.swiper-container,
.swiper-slide {
    position: relative;
    font-weight: 300
}

.site_header-nav .left-item,
.swiper-container-no-flexbox .swiper-slide {
    float: left
}


.swiper-slide,
body,
html {
    height: 100%
}

html {
    overflow-y: auto
}

body {
    line-height: 1
}

* {
    -webkit-tap-highlight-color: transparent
}

body {
    -webkit-text-size-adjust: 100%;
    background: #fff;
    color: #393939;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    font-family: Rubik, sans-serif;
    min-width: 320px
}

a,
a:visited {
    color: #393939;
    text-decoration: underline
}

a:hover {
    text-decoration: none
}

.site_header-nav {
    margin: 0 auto;
    max-width: 1140px;
    box-sizing: border-box;
    padding: 0 3px;
    text-transform: uppercase;
    text-align: center
}

.site_header-nav-wrap {
    background: #fff;
    height: 80px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 2
}

.site_header-nav .right-item {
    float: right
}

.site_header-nav .logo-item {
    margin: 21px 32px 0 0
}

@media screen and (max-width:1050px) {
    .site_header-nav .logo-item {
        display: none
    }
}

.site_header-nav .logo-item img {
    width: 186px
}

.site_header-nav .btn-item {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    height: 38px;
    line-height: 40px;
    padding: 0 36px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    text-decoration: none;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: #25292c;
    transition: all .15s ease
}

@media screen and (max-width:1050px) {
    .site_header-nav .btn-item {
        display: block;
        float: none;
        height: 56px;
        line-height: 58px;
        margin: 17px 24px 0;
        text-align: center;
        border-radius: 28px
    }
    .site_header-nav .btn-item.transparent {
        border-bottom: 1px solid #eef0f1;
        border-top: 1px solid #eef0f1;
        margin: 0;
        height: 50px;
        line-height: 52px;
        text-align: left
    }
    .site_header-nav .btn-item.transparent:active {
        background: rgba(0, 0, 0, .05)!important
    }
    .site_header-nav .btn-item.transparent:hover {
        text-decoration: none!important
    }
    .site_header-nav .btn-item.transparent+.transparent {
        margin-top: -1px
    }
}

.site_header-nav .btn-item:hover {
    background-color: #c82590;
    color: #fff
}

.site_header-nav .btn-item:active {
    background-color: #193e9c;
    color: #fff
}

.site_header-nav .btn-item.transparent {
    background: 0 0!important;
    margin: 0 25px;
    padding: 0 20px;
    border: none
}

.site_header-nav .btn-item.transparent.login {
    margin: 20px 13px 0 0;
    padding: 0 36px
}

.site_header-nav .btn-item.transparent:hover {
    text-decoration: underline;
    color: #25292c
}

.site_header-nav .btn-item.nav-links {
    margin-top: 0;
    padding: 19px;
    border-bottom: 4px solid #fff;
    transition: border-color .15s ease;
    border-radius: 0;
    font-size: 14px;
    line-height: 42px
}

.site_header-nav .btn-item.nav-links:hover {
    color: #c82590;
    text-decoration: none
}

@media screen and (max-width:1050px) {
    .site_header-nav .btn-item.nav-links {
        border-bottom: none!important;
        height: 35px;
        line-height: 35px;
        padding: 10px 25px
    }
}

.site_header-nav .btn-item.active {
    border-color: #c82590;
    color: #c82590!important
}

.site_header-nav .btn-item.active:hover {
    text-decoration: none
}

.site_header-nav .btn-item_blue {
    background-color: #f79461;
    color: #fff
}

.site_header-nav .btn-item_blue:hover {
    background-color: #fff;
    color: #25292c
}

.site_header-nav .btn-item_blue:active {
    background-color: #fff;
    border-color: #d0d0d0;
    color: #25292c
}

.header-employee {
    position: relative;
    background: #E4F9F8
}

.header-employee__aligner {
    height: 740px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1136px;
    position: relative;
    padding: 163px 30px 0;
    text-align: center;
    color: #000
}

.header-employee.employer-style:after,
.header-employee:before {
    display: block;
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    left: 0
}

.header-employee:before {
    bottom: 0;
    background-size: 2780px, 660px
}

@media screen and (max-width:1140px) {
    .header-employee:before {
        background-position: calc(100% + 820px) 100%
    }
}

.header-employee h1 {
    font-size: 50px;
    line-height: .8;
    color: #282c41;
    margin-bottom: 36px;
    font-weight: 700
}

@media screen and (max-width:850px) {
    .header-employee__aligner {
        padding: 105px 23px 0;
        height: 832px
    }
    .header-employee h1 {
        font-size: 34px;
        font-weight: 700;
        line-height: 1.32;
        margin-bottom: 19px
    }
}

.header-employee .head-text {
    max-width: 860px;
    padding: 0 20px;
    margin: 0 auto 40px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    color: #2a2c3f
}

@media screen and (max-width:850px) {
    .header-employee .head-text {
        font-size: 16px;
        line-height: 1.88;
        max-width: 700px;
        padding: 0;
        margin-bottom: 27px
    }
}

.header-employee .apply-button {
    margin: 0 auto
}

@media screen and (max-width:530px) {
    .header-employee__aligner {
        height: 931px
    }
    .header-employee:before {
        background-position: calc(100% + 947px) 100%
    }
    .header-employee .apply-button {
        width: 240px;
        min-width: 0
    }
}

.header-employee.employer-style {
    border-bottom-color: #d4cfdb;
    background-color: #f9f5e4
}

.header-employee.employer-style .header-employee__aligner {
    padding-top: 139px;
    max-width: 1050px
}

@media screen and (max-width:800px) {
    .header-employee.employer-style .header-employee__aligner {
        padding-top: 104px;
        padding-left: 32px;
        padding-right: 32px;
        height: 882px
    }
}

.header-employee.employer-style h1 {
    font-size: 40px;
    line-height: 1.38;
    margin-bottom: 40px
}

.header-employee.employer-style:after {
    bottom: 63px;
    background-size: 1439px 461px;
    pointer-events: none
}

@media screen and (max-width:800px) {
    .header-employee.employer-style h1 {
        font-size: 34px;
        line-height: 1.32
    }
    .header-employee.employer-style:after {
        background-position: calc(50% + 313px) 100%;
        bottom: 47px
    }
}

@media screen and (max-width:530px) {
    .header-employee.employer-style .header-employee__aligner {
        padding-left: 20px;
        padding-right: 20px;
        height: 1001px
    }
    .header-employee.employer-style:after {
        background-position: calc(50% + 233px) 100%;
        bottom: 40px
    }
}

.header-employee.employer-style:before {
    width: 100%;
    height: 115px;
    left: 0;
    bottom: 0;
    top: auto;
    background: #E7E1C7
}

.employee-ftrs {
    padding-bottom: 20px;
    margin-top: -4px;
    color: #282c41
}

@media screen and (max-width:800px) {
    .employee-ftrs {
        padding-bottom: 6px
    }
}

.employee-ftrs__title {
    float: left;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.46;
    width: 195px
}

.employee-ftrs-features {
    margin-left: 237px
}

@media screen and (max-width:950px) {
    .employee-ftrs__title {
        float: none;
        text-align: center;
        font-size: 30px;
        line-height: 1.17;
        width: auto;
        margin-bottom: 26px
    }
    .employee-ftrs-features {
        text-align: center;
        max-width: 500px;
        margin: 0 auto
    }
}

.employee-ftrs-features__item {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    width: 155px;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
    padding: 12px 5px 5px 67px;
    list-style-type: none
}

.employee-ftrs-features__item+.header-footer-features__item {
    margin-left: -4px
}

@media screen and (max-width:950px) {
    .employee-ftrs-features__item {
        margin-left: 0!important;
        width: 155px!important;
        left: 0!important;
        padding-right: 5px!important;
        padding-bottom: 12px
    }
}

.employee-ftrs-features__item:before {
    display: block;
    content: '';
    position: absolute;
    top: 6px;
    left: 2px;
    width: 60px;
    height: 60px;
    background-size: 31px 24px
}

.employee-ftrs-features__item.debt {
    width: 145px
}

.employee-ftrs-features__item.debt:before {
    background-size: 24px 30px
}

.employee-ftrs-features__item.home {
    width: 164px;
    left: 10px
}

.employee-ftrs-features__item.home:before {
    background-size: 38px 26px
}

.employee-ftrs-features__item.money {
    padding-right: 0;
    width: 130px
}

.employee-ftrs-features__item.money:before {
    background-size: 30px 30px
}

.apply-button,
a.apply-button {
    display: block;
    text-decoration: none;
    color: #fff;
    text-align: center;
    width: 240px;
    height: 70px;
    line-height: 72px;
    border-radius: 35px;
    background-color: #c82590;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase
}

.apply-button:hover,
a.apply-button:hover {
    background-color: #f79461
}

.apply-button:active,
a.apply-button:active {
    background-color: #193e9c;
    outline: 0
}

@media screen and (max-width:530px) {
    .employee-ftrs-features {
        max-width: 300px
    }
    .apply-button,
    a.apply-button {
        width: auto;
        min-width: 260px
    }
}

.mobileMenuToggler {
    position: fixed;
    z-index: 2;
    top: 26px;
    right: 25px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: none
}

.mobileMenuToggler:active span,
.mobileMenuToggler:active span:after,
.mobileMenuToggler:active span:before {
    background-color: #2aa2ce
}

.mobileMenuToggler span {
    display: block;
    position: absolute;
    z-index: 2;
    border-radius: 2px;
    background: #50B6DB;
    width: 32px;
    height: 4px;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -16px;
    transition: all .15s ease-in-out
}

.mobileMenuToggler span:after,
.mobileMenuToggler span:before {
    content: ' ';
    display: block;
    position: absolute;
    transition: all .15s ease-in-out;
    left: 0;
    top: -8px;
    width: 100%;
    height: 100%;
    background-color: #50B6DB;
    border-radius: 2px
}

.mobileMenuToggler span:after {
    top: 8px
}

.mobileMenuToggler:after {
    display: block;
    content: ' ';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px
}

.mobileOpenNav .mobileMenuToggler span {
    background-color: transparent
}

.mobileOpenNav .mobileMenuToggler span:after,
.mobileOpenNav .mobileMenuToggler span:before {
    transform: rotate(45deg);
    top: 0;
    background-color: #a8a8a8
}

.mobileOpenNav .mobileMenuToggler span:after {
    transform: rotate(-45deg)
}

@media screen and (max-width:1050px) {
    .mobileMenuToggler {
        display: block
    }
    .site_header-nav {
        display: none!important
    }
    .mobileOpenNav .site_header-nav {
        position: absolute;
        z-index: 2;
        padding: 85px 0 28px;
        display: flex!important;
        flex-direction: column;
        top: 0;
        right: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0 30px 60px 0 rgba(56, 56, 56, .3)
    }
    .mobileOpenNav .site_header-nav .btn-item.transparent {
        margin-left: 0;
        margin-right: 0!important
    }
    .mobileOpenNav .site_header-nav .btn-item.transparent.login {
        margin-top: -1px;
        order: 101;
        text-align: center
    }
    .mobileOpenNav .site_header-nav .order-last {
        order: 100
    }
}

.item-d-h {
    display: none
}

.logo-item-m {
    position: fixed;
    top: 25px;
    left: 24px
}

.logo-item-m img {
    width: 144px;
    height: 30px
}

@media screen and (max-width:1050px) {
    .item-d-h {
        display: block;
        z-index: 2
    }
    .item-m-h {
        display: none
    }
}

.header-home {
    position: relative;
    width: 100%;
    overflow: hidden
}

.header-home h1 {
    font-size: 44px;
    font-weight: 700;
    line-height: 1.17;
    letter-spacing: .7px;
    margin-bottom: 18px
}

@media screen and (max-width:800px) {
    .header-home h1 {
        font-size: 34px;
        line-height: 1.32;
        margin-bottom: 21px
    }
}

.header-home .head-text {
    font-size: 18px;
    line-height: 1.67;
    font-weight: 300;
    max-width: 765px;
    padding: 0 20px;
    margin: 0 auto 41px
}

@media screen and (max-width:800px) {
    .header-home .head-text {
        font-size: 16px;
        line-height: 1.88;
        margin-bottom: 30px;
        padding: 0 13px 6px
    }
    .header-home .head-text br {
        display: none
    }
}

.header-home:after,
.header-home:before {
    position: absolute;
    z-index: -1;
    height: 50%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #9ED164;
    content: ''
}

.header-home:after {
    top: 0;
    bottom: auto;
    background: #7AEDFF
}

.header-home__titles {
    padding: 0 20px
}

.header-home__aligner {
    position: relative
}

.header-home__sub-aligner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 819px;
    padding: 140px 0 30px;
    text-align: center;
    box-sizing: border-box
}

.header_fixed,
.header_fixed .logo-item-m,
.header_fixed .mobileMenuToggler {
    position: fixed
}

@media screen and (max-width:530px) {
    .header-home__sub-aligner {
        height: 1249px;
        padding: 103px 0 30px;
        background-size: auto 59%
    }
}

@media screen and (max-width:800px) {
    .header-home__sub-aligner {
        height: 1247px;
        padding: 104px 0 32px;
        background-size: auto 59%;
        font-size: 34px;
        line-height: 1.32
    }
}

.header-home_about {
    height: 80px;
    overflow: inherit
}

.header-home_about .site_header-nav .btn-item.transparent {
    margin: 0 2.2px
}

.header-home_about .site_header-nav .btn-item.transparent.login {
    margin: 20px 13px 0 0
}

.header_fixed {
    z-index: 10
}

.header_fixed+div {
    padding-top: 80px
}

.base-content {
    width: 100%;
    overflow: hidden;
    padding-top: 69px
}

.base-content__eployer-why {
    padding-top: 88px;
    padding-bottom: 50px
}

@media screen and (max-width:800px) {
    .base-content__eployer-why {
        padding-bottom: 13px
    }
}

.base-content__eployer-why .base-content__heading {
    margin-bottom: 51px
}

.base-content__eployer-why .emplyee-features__item {
    line-height: 1.56
}

.base-content__eployer-why .emplyee-features__item strong {
    line-height: 1.2;
    padding-bottom: 9px
}

.base-content__how-it-works {
    background-color: #f9f9f9;
    border-top: solid 1px #e9e9e9;
    border-bottom: solid 1px #e9e9e9;
    padding-top: 84px
}

.base-content__how-it-works.fedgov_layout {
    padding-top: 92px;
    border: none
}

.base-content__how-it-works.fedgov_layout .base-content__heading {
    padding-bottom: 20px
}

.base-content__how-it-works.fedgov_layout .base-content__paragraph {
    max-width: 900px
}

@media screen and (max-width:800px) {
    .base-content__how-it-works.fedgov_layout {
        padding-top: 55px
    }
    .base-content__how-it-works.fedgov_layout .base-content__paragraph {
        margin-bottom: 25px
    }
}

@media screen and (max-width:530px) {
    .base-content__how-it-works.fedgov_layout .base-content__paragraph {
        margin-bottom: 60px
    }
}

.base-content__how-it-works .base-content__heading {
    padding-bottom: 73px
}

@media screen and (max-width:800px) {
    .base-content__how-it-works .base-content__heading {
        padding-bottom: 32px
    }
}

@media screen and (max-width:530px) {
    .base-content__form .base-content__paragraph {
        padding: 0
    }
}

.base-content__apply {
    position: relative;
    padding-bottom: 22px;
    margin-top: -106px;
    padding-top: 181px
}

@media screen and (max-width:1150px) {
    .base-content__apply {
        margin-top: 0
    }
}

.base-content__apply .base-content__heading {
    line-height: 1.5;
    max-width: 800px
}

.base-content__apply-link {
    text-align: center;
    padding: 15px 0 136px
}

@media screen and (max-width:800px) {
    .base-content__apply .base-content__heading {
        line-height: 1.25;
        margin-top: 5px;
        margin-bottom: 20px
    }
    .base-content__apply-link {
        padding: 4px 0 55px
    }
}

@media screen and (max-width:530px) {
    .base-content__apply-link {
        padding: 0 0 45px
    }
}

.base-content__apply-link a.apply-button {
    display: inline-block
}

.base-content__apply-link-see {
    text-align: center;
    padding: 15px 0 0
}

.base-content__apply-link-see a.apply-button {
    display: inline-block;
    background: #fff;
    color: #c82590;
    transition: all .15s ease
}

.base-content__apply-link-see a.apply-button:hover {
    background-color: #f79461;
    color: #fff
}

.base-content__apply-link-see a.apply-button:active {
    background-color: #193e9c;
    outline: 0;
    color: #fff
}

.base-content__apply:after {
    display: block;
    position: absolute;
    top: 0;
    height: 524px;
    background-repeat: no-repeat;
    background-position: 52% 0
}

@media only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:192dpi),
only screen and (min-resolution:2dppx) {
    .base-content__apply:after {
        background-size: 1368px 524px
    }
}

@media screen and (max-width:800px) {
    .base-content__apply:after {
        display: none
    }
    .base-content {
        padding-top: 54px
    }
}

.base-content__pt51 {
    padding-top: 51px
}

@media screen and (max-width:800px) {
    .base-content__pt51 {
        padding-top: 62px
    }
}

.base-content__aligner {
    margin: 0 auto;
    max-width: 1136px;
    padding: 0 30px;
    color: #282c41;
    z-index: 1;
    position: relative
}

.base-content__aligner_1460 {
    max-width: 1460px
}

.base-content__heading {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .15px;
    padding-bottom: 14px;
    max-width: 880px;
    margin: 0 auto 14px
}

.base-content__heading_bigger {
    font-size: 45px
}

@media screen and (max-width:800px) {
    .base-content__heading {
        font-size: 30px;
        line-height: 1.17
    }
    .base-content__heading_bigger {
        font-size: 34px;
        line-height: 1.32
    }
}

.base-content__sub-heading {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.67;
    text-align: center
}

@media screen and (max-width:800px) {
    .base-content__sub-heading {
        font-size: 24px;
        line-height: 1.46;
        padding-bottom: 18px
    }
}

.base-content__paragraph {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    color: #2a2c3f;
    max-width: 850px;
    margin: 0 auto 50px;
    padding-top: 4px;
    text-align: center
}

.base-content__paragraph.mb27 {
    margin-bottom: 27px
}

.base-content__paragraph_map {
    margin-bottom: 69px;
    max-width: 650px
}

@media screen and (max-width:800px) {
    .base-content__paragraph {
        font-size: 16px;
        line-height: 1.5;
        padding-top: 0;
        padding-left: 26px;
        padding-right: 26px;
        margin: -2px auto 39px
    }
}

.how-does-it-work {
    position: relative;
    min-height: 370px;
    margin-bottom: 17px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 51px
}

.how-does-it-work-wrap {
    padding: 35px 0 5px 685px;
    position: relative
}

.how-does-it-work-align {
    display: flex;
    flex-direction: column
}

.how-does-it-work-wrap .img {
    display: block;
    position: absolute;
    content: ' ';
    top: 0;
    left: -146px;
    width: 878px
}

.how-does-it-work-wrap .img img {
    display: block;
    width: 100%
}

@media screen and (max-width:647px) {
    .how-does-it-work-wrap .img img {
        width: 147%;
        margin-left: -23.5%
    }
}

@media screen and (max-width:1150px) {
    .how-does-it-work-wrap {
        padding: 0 20px;
        text-align: center;
        margin-bottom: 5px
    }
    .how-does-it-work {
        min-height: 0;
        padding-top: 34px;
        margin-bottom: 28px
    }
    .how-does-it-work-wrap .img {
        display: block;
        left: 0;
        position: relative;
        max-width: 647px;
        width: 100%;
        margin: 0 auto 61px
    }
    .how-does-it-work__title {
        font-size: 24px
    }
}

.how-does-it-work .tab-content {
    display: none;
    position: relative;
    z-index: 2
}

.how-does-it-work .tab-content.active {
    display: block
}

.how-does-it-work__title {
    display: block;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.88;
    color: #282c41;
    padding-bottom: 15px
}

.how-does-it-work__text {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    color: #2a2c3f;
    padding-bottom: 23px
}

.employee-tabs {
    text-align: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    z-index: 2;
    margin: 0 53px 0 31px;
    white-space: nowrap
}

@media screen and (max-width:1150px) {
    .employee-tabs {
        margin: 0 auto;
        max-width: 228px;
        padding-right: 8px
    }
}

.employee-tabs:before {
    display: block;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    margin-top: -.5px;
    background: #f89b6c
}

.employee-tabs__item {
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    position: relative
}

.employee-tabs__item_num {
    display: block;
    cursor: pointer;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    color: #fff;
    background-color: #f89b6c;
    font-size: 20px;
    font-weight: 500;
    transition: background-color .15s ease;
    position: relative;
    margin-left: -20px;
    left: 0
}

@media screen and (max-width:1150px) {
    .employee-tabs__item_num {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 16px!important;
        font-weight: 400;
        margin-left: -16px
    }
}

.employee-tabs__item_num:hover {
    background-color: #c82590
}

.employee-tabs__item_num:after,
.employee-tabs__item_num:before {
    display: block;
    content: '';
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 38px;
    height: 38px;
    transition: all .15s ease;
    border-width: 0
}

@media screen and (max-width:1150px) {
    .employee-tabs__item_num:after,
    .employee-tabs__item_num:before {
        display: none
    }
}

.employee-tabs__item.active:before,
.employee-tabs__item:before,
.swiper-container:after,
.swiper-container:before {
    display: block;
    position: absolute;
    content: ' '
}

.employee-tabs__item:before {
    transition: all .15s ease;
    width: 0;
    height: 1px;
    top: 50%;
    left: 0;
    margin-top: -.5px;
    background: #c82590
}

.employee-tabs__item.active:before {
    width: 115%;
    left: -57.5%
}

.employee-tabs__item.active:first-child:before {
    width: 57.5%;
    left: 0
}

.employee-tabs__item.active:last-child:before {
    width: 57.5%
}

@media screen and (max-width:1150px) {
    .employee-tabs__item.active:before {
        display: block;
        position: absolute;
        content: ' ';
        width: 100%;
        left: -50%
    }
    .employee-tabs__item.active:first-child:before {
        width: 50%;
        left: 0
    }
    .employee-tabs__item.active:last-child:before {
        width: 50%
    }
}

.employee-tabs__item.active .employee-tabs__item_num {
    background-color: #c82590;
    font-size: 24px
}

.employee-tabs__item.active .employee-tabs__item_num:before {
    border: 20px solid #c82590;
    top: -19px;
    left: -19px
}

.employee-tabs__item.active .employee-tabs__item_num:after {
    width: 70px;
    height: 70px;
    border: 3px solid #f9f9f9;
    top: -18px;
    left: -18px
}


.footer {
    position: relative;
    padding: 73px 0 63px;
    font-size: 14px;
    font-weight: 400;
    color: #9fa5b0;
    box-sizing: border-box
}

.footer a {
    color: #000;
    border-bottom: 1px solid transparent;
    transition: border-color .15s ease-in-out
}

.footer a:hover {
    border-color: #000
}

.footer a.blue {
    color: #f79461
}

.footer a.blue:hover {
    border-color: #f79461
}

.footer a.phone {
    cursor: default;
    border-bottom: none
}

.footer__centered {
    width: 100%;
    max-width: 1156px;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box
}

.footer__head {
    display: flex;
    padding-bottom: 24px;
    width: 100%;
    max-width: 936px;
    margin: 0 auto
}

.footer__logo {
    position: relative;
    display: block;
    width: 146px;
    margin-top: -15px;
    margin-bottom: 21px;
    border-bottom: none!important
}

.footer__logo img {
    display: block;
    width: 100%
}

.footer__socials a {
    display: inline-block;
    vertical-align: top;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: opacity .15s ease-in-out;
    border-bottom: none
}

.footer__socials a:last-child {
    margin-right: 0
}

.footer__socials a:hover {
    opacity: .85
}

.footer__socials a img {
    width: 100%;
    height: 100%
}

.footer__aside {
    display: flex;
    flex-direction: column;
    width: 146px;
    margin-right: 8.4%
}

.footer__aside-nav {
    margin-bottom: 20px
}

.footer__aside-nav div {
    margin-bottom: 16px
}

.footer__aside-nav div:last-child {
    margin-bottom: 0
}

.footer__aside-nav a {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500
}

.footer__aside-copyrights {
    margin-bottom: 23px;
    font-weight: 300;
    letter-spacing: .2px;
    line-height: 1.2
}

.footer__menu {
    display: flex
}

.footer__menu-col {
    margin-right: 116px
}

.footer__menu-col:last-child {
    margin-right: 0
}

.footer__menu-title {
    display: block;
    margin-bottom: 21px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #959ba8
}

.footer__menu-list li {
    margin-bottom: 16px;
    list-style-type: none
}

.footer__menu-list a {
    color: #000;
    text-decoration: none
}

.footer__help {
    padding-top: 17px
}

.footer__help-button {
    position: relative;
    height: 40px;
    width: 100px;
    font-size: 14px;
    border: none;
    color: #fff;
    margin-top: -12px;
    border-radius: 20px;
    background-color: #f79461;
    text-transform: uppercase
}

.footer__help-button:hover {
    background-color: #79c9e6
}

.footer__help-button:active {
    background-color: #27a5d2;
    outline: 0
}

.footer__help-button:focus {
    outline: 0
}

@media screen and (max-width:800px) {
    .footer__help-button {
        margin-top: 4px
    }
}

.footer__help-button-img {
    position: absolute;
    top: 6px;
    left: 14px;
    width: 28px;
    height: 28px
}

.footer__help-button-text {
    position: absolute;
    top: 12px;
    right: 21px
}

.footer__info {
    padding-top: 37px;
    border-top: solid 1px rgba(214, 217, 218, .5)
}

.footer__info-list {
    width: 100%;
    max-width: 934px;
    margin: 0 auto;
    box-sizing: border-box
}

.footer__info-list li {
    position: relative;
    margin-bottom: 2px;
    padding: 0 0 0 17px;
    color: #9fa5b0;
    font-weight: 400;
    list-style-type: none;
    line-height: 1.3;
    font-size: 10pt
}

.footer__info-list li:before {
    opacity: .5;
    position: absolute;
    left: 6px;
    top: 3px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #959ba8
}

.footer__info-list li:last-child {
    margin-bottom: 0
}

@media screen and (max-width:800px) {
    .footer {
        padding: 49px 0 39px
    }
    .footer__head {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding-bottom: 40px
    }
    .footer__logo {
        margin-top: 0;
        margin-bottom: 32px
    }
    .footer__aside {
        width: auto;
        margin-right: 0;
        margin-bottom: 40px;
        align-items: center
    }
    .footer__aside-nav {
        margin-bottom: 41px
    }
    .footer__aside-copyrights {
        margin-bottom: 20px
    }
    .footer__menu {
        flex-direction: column;
        align-items: center;
        text-align: center
    }
    .footer__menu-col {
        margin-right: 0;
        margin-bottom: 25px
    }
    .footer__menu-col:last-child {
        margin-bottom: 0
    }
    .footer__help {
        padding-top: 1px
    }
    .footer__info {
        padding-top: 36px;
        margin: 0 -15px
    }
    .footer__info-list {
        padding: 0 32px
    }
    .footer__info-list li {
        margin-bottom: 8px;
        line-height: 1.25
    }
}

@media screen and (max-width:530px) {
    .footer {
        padding: 44px 0 34px
    }
    .footer__info {
        padding-top: 34px
    }
    .footer__info-list {
        padding: 0 22px
    }
    .footer__info-list li {
        margin-bottom: 11px
    }
}

.zEWidget-launcher {
    display: none!important
}

.emplyee-features {
    text-align: center;
    margin: -21px -35px 0
}

@media screen and (max-width:800px) {
    .emplyee-features {
        margin-left: auto;
        margin-right: auto;
        max-width: 800px;
        padding-bottom: 43px
    }
}

.emplyee-features__item {
    list-style-type: none;
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 225px;
    margin: 0 32px 40px
}

.emplyee-features_fed .emplyee-features__item {
    margin-top: -17px;
    margin-bottom: 21px
}

@media screen and (max-width:800px) {
    .emplyee-features__item {
        width: 173px;
        margin-bottom: 3px
    }
}

@media screen and (max-width:530px) {
    .emplyee-features {
        padding-bottom: 0
    }
    .emplyee-features__item {
        margin-bottom: 37px
    }
}

.emplyee-features__item strong {
    display: block;
    color: #000;
    font-size: 18px;
    padding-bottom: 15px
}

.emplyee-features__item strong.employee-features__big-text {
    font-weight: 700;
    font-size: 100px;
    color: #c82590;
    padding: 49px 0 20px
}

.emplyee-features__item.befored:before {
    display: block;
    left: 0;
    width: 100%;
    height: 134px;
    margin-bottom: 9px;
    background-position: 50% 50%;
    background-repeat: no-repeat
}

.emplyee-features__item.reduce:before {
    background-size: 150px 150px
}

.emplyee-features__item.augment:before {
    background-size: 150px 150px
}

.emplyee-features__item.create:before {
    background-size: 150px 150px
}

.emplyee-features__item.provide:before {
    background-size: 150px 150px
}

.emplyee-features__asteriks {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.79;
    color: #818181;
    padding-bottom: 83px
}

@media screen and (max-width:800px) {
    .emplyee-features__item strong.employee-features__big-text {
        padding-top: 23px
    }
    .emplyee-features__asteriks {
        max-width: 280px;
        margin: 0 auto;
        padding-bottom: 59px
    }
}

.emplyee-features_small {
    margin-bottom: 30px
}

.emplyee-features_small .emplyee-features__item {
    margin: 0 30px 40px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    text-align: center;
    color: #282c41
}

.emplyee-features_small .emplyee-features__item strong {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    color: #282c41;
    padding-bottom: 10px
}

.emplyee-features_small .emplyee-features__item strong.employee-features__big-text {
    font-weight: 700;
    font-size: 60px;
    color: #f79461;
    padding: 30px 0 10px
}

@media screen and (max-width:800px) {
    .emplyee-features_small .emplyee-features__item strong.employee-features__big-text {
        padding-top: 23px
    }
}

@media screen and (max-width:530px) {
    .emplyee-features_small {
        padding-top: 18px;
        margin-bottom: 38px
    }
}

@media screen and (max-width:800px) {
    .emplyee-features_small {
        padding-top: 21px;
        margin-bottom: -3px
    }
    .emplyee-features_small .emplyee-features__item {
        width: 220px;
        margin: 0 0 36px
    }
}

.emplyee-faq {
    max-width: 980px;
    margin: 0 auto;
    list-style-type: none;
    padding: 30px 85px 80px;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1)
}

@media screen and (max-width:800px) {
    .emplyee-faq {
        margin: 0 -30px;
        padding: 30px 24px 64px
    }
}

.emplyee-faq__item {
    position: relative;
    border-bottom: 1px solid #dfe4e6
}

.emplyee-faq__item.active .q {
    background-color: #c82590
}

.emplyee-faq__item.active .arr {
    transform: rotate(180deg)
}

.emplyee-faq__item.active .arr .arrow-blue--bottom {
    fill: #f89b6c
}

.emplyee-faq__item.active .emplyee-faq__text {
    max-height: 1000px
}

.emplyee-faq__text {
    max-height: 0;
    transition: max-height .15s ease-out;
    overflow: auto;
    position: relative;
    top: -20px
}

.emplyee-faq__text p {
    padding: 0 100px 31px 127px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67
}

@media screen and (max-width:800px) {
    .emplyee-faq__text p {
        padding: 13px 30px 15px 76px;
        font-size: 16px;
        line-height: 1.61
    }
}

@media screen and (max-width:530px) {
    .emplyee-faq__text p {
        padding-left: 72px
    }
}

.emplyee-faq__title {
    cursor: pointer;
    position: relative;
    display: block;
    padding: 38px 70px 41px 127px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 700;
    color: #000;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none
}

@media screen and (max-width:800px) {
    .emplyee-faq__title {
        font-size: 18px;
        font-weight: 500;
        padding: 31px 50px 31px 75px
    }
}

.emplyee-faq__title:hover {
    color: #c82590
}

.emplyee-faq__title .q {
    position: absolute;
    top: 50%;
    left: 37px;
    margin-top: -30px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    transition: all .1s ease;
    background-size: 27px 32px
}

@media screen and (max-width:800px) {
    .emplyee-faq__title .q {
        width: 50px;
        height: 50px;
        left: 10px;
        margin-top: -25px
    }
}

@media screen and (max-width:530px) {
    .emplyee-faq__title {
        padding: 23px 30px 23px 73px;
        line-height: 1.23
    }
    .emplyee-faq__title .q {
        top: 46px;
        background-size: 22.5px 26.1px
    }
}

.emplyee-faq__title .arr {
    position: absolute;
    top: 50%;
    right: 36px;
    margin-top: -10px;
    transition: all .2s ease
}

.emplyee-faq__title .arr .arrow-blue--bottom {
    transition: all .1s ease
}

.refer_employer {
    max-width: 530px;
    border-radius: 4px;
    position: relative;
    margin: 0 auto 90px
}

@media screen and (max-width:800px) {
    .emplyee-faq__title .arr {
        right: 3px
    }
    .refer_employer {
        margin-bottom: 40px
    }
}

.refer_employer-news {
    max-width: 790px;
    margin-bottom: 78px
}

.refer_employer:after {
    display: block;
    clear: both
}

.refer_employer .load-container,
.refer_employer__success {
    display: none;
    margin-right: -20px
}

.refer_employer .load-container button.apply-button,
.refer_employer__success button.apply-button {
    display: inline-block;
    vertical-align: middle;
    width: 108px
}

@media screen and (max-width:851px) {
    .refer_employer-news {
        margin-bottom: 0
    }
    .refer_employer {
        max-width: 439px
    }
    .refer_employer .load-container button.apply-button,
    .refer_employer__success button.apply-button {
        display: block;
        width: 120px;
        margin: 10px auto
    }
    .refer_employer .load-container_text,
    .refer_employer__success_text {
        width: auto
    }
}

.refer_employer .load-container_text,
.refer_employer__success_text {
    display: inline-block;
    vertical-align: middle;
    max-width: 160px;
    min-height: 32px;
    font-size: 16px;
    color: #74ba2d;
    padding: 10px 20px 10px 67px;
}

.refer_employer__i {
    width: 415px;
    height: 50px;
    border-radius: 25px 0 0 25px;
    float: left;
    box-sizing: border-box;
    border: 1px solid #dde2e5;
    background: #fff
}

@media screen and (max-width:851px) {
    .refer_employer__i {
        margin-bottom: 20px
    }
    .refer_employer__i.employee_i {
        width: 324px
    }
    .refer_employer__i.employee_i input {
        width: 83%!important
    }
    .refer_employer__i.errored {
        margin-bottom: 40px
    }
}

@media screen and (max-width:530px) {
    .emplyee-faq__title .arr {
        top: 35px
    }
    .refer_employer__i {
        border-radius: 25px;
        width: 100%!important
    }
}

.refer_employer__i.errored {
    border-color: #ff4800
}

.refer_employer__i .notifer {
    color: #ff4800;
    padding: 5px 20px 0;
    font-size: 14px
}

.refer_employer__i input {
    background: 0 0;
    font-size: 16px;
    width: calc(100% - 64px);
    height: 50px;
    margin-left: 32px;
    border: none;
    color: #000
}

.refer_employer__i input::-webkit-input-placeholder {
    color: #c6c6c6;
    font-weight: 300
}

.refer_employer__i input:-moz-placeholder {
    color: #c6c6c6;
    font-weight: 300
}

.refer_employer__i input::-moz-placeholder {
    color: #c6c6c6;
    font-weight: 300
}

.refer_employer__i input:-ms-input-placeholder {
    color: #c6c6c6;
    font-weight: 300
}

.refer_employer__i-name {
    width: 240px;
    margin-right: 20px;
    border-radius: 25px
}

@media screen and (max-width:851px) {
    .refer_employer__i-name {
        margin-right: auto;
        margin-left: auto;
        float: none;
        max-width: 392px;
        width: auto
    }
    .refer_employer__i-name input {
        width: calc(100% - 40px);
        margin-left: 20px
    }
}

.refer_employer__i-mail {
    width: 370px;
    border-radius: 25px;
    margin-right: 20px
}

@media screen and (max-width:851px) {
    .refer_employer__i-mail {
        margin-right: auto;
        margin-left: auto;
        float: none;
        max-width: 392px;
        width: auto
    }
    .refer_employer__i-mail input {
        width: calc(100% - 40px);
        margin-left: 20px
    }
}

.refer_employer__s {
    float: left;
    margin-left: -25px
}

.refer_employer__s.ml0 {
    margin-left: 0
}

@media screen and (max-width:530px) {
    .refer_employer__s {
        margin: 0;
        float: none
    }
    .refer_employer .apply-button {
        margin: 0 auto
    }
}

.refer_employer .apply-button {
    border: none;
    padding-top: 4px;
    width: 140px;
    height: 50px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1
}

@media screen and (max-width:851px) {
    .refer_employer__s.ml0 {
        float: none
    }
    .refer_employer .apply-button.wide {
        display: block;
        width: 100%;
        max-width: 392px;
        margin: 0 auto
    }
}

.refer_employer .apply-button:disabled {
    background-color: #eef0f1;
    cursor: default;
    color: #a8a8a8
}

.refer_employer.success_result .refer_employer__i,
.refer_employer.success_result .refer_employer__s {
    display: none
}

.refer_employer.success_result .refer_employer__success {
    display: block;
    text-align: center
}

.employer_popup {
    position: fixed;
    z-index: 1000;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity .1s ease
}

.employer_popup.shown {
    visibility: visible;
    opacity: 1
}

.employer_popup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color .1s ease
}

.employer_popup-close:hover {
    background-color: #f2f2f2
}

.employer_popup-close svg {
    position: relative;
    top: 8px;
    left: 8px;
    display: block
}

.employer_popup .refer_employer {
    margin-bottom: 22px;
    text-align: left
}

.employer_popup .refer_employer__wrap {
    position: relative;
    max-width: 880px;
    width: 100%;
    border-radius: 4px;
    background-color: #f8f9fb;
    box-shadow: 0 20px 40px 0 rgba(14, 11, 11, .3);
    padding: 58px 40px;
    text-align: center;
    box-sizing: border-box;
    overflow: auto
}

@media screen and (max-width:851px) {
    .employer_popup-close {
        top: 5px;
        right: 5px
    }
    .employer_popup .refer_employer {
        margin-bottom: 0
    }
    .employer_popup .refer_employer__wrap {
        padding: 40px 20px
    }
}

.employer_popup .refer_employer__wrap-title {
    display: block;
    font-size: 40px;
    color: #000;
    padding-bottom: 28px;
    font-weight: 400
}

@media screen and (max-width:851px) {
    .employer_popup .refer_employer__wrap-title {
        font-size: 30px;
        padding-bottom: 18px
    }
}

.employer_popup .refer_employer__wrap-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25;
    color: #25292c;
    padding-bottom: 55px
}

@media screen and (max-width:851px) {
    .employer_popup .refer_employer__wrap-text {
        padding-bottom: 38px
    }
}

.plans-n-pricing {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 auto 50px
}

@media screen and (max-width:800px) {
    .plans-n-pricing {
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px
    }
}

.plans-n-pricing__item {
    width: 31.5%;
    flex-basis: 31.5%;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 5px 30px 0 rgba(113, 147, 179, .2);
    padding: 41px 23px 44px
}

@media screen and (max-width:800px) {
    .plans-n-pricing__item {
        width: 272px;
        flex-basis: 272px;
        margin-bottom: 16px;
        padding-top: 31px;
        padding-bottom: 37px
    }
}

.plans-n-pricing__heading {
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: #000;
    padding-bottom: 19px
}

.plans-n-pricing ul {
    list-style-type: none;
    text-align: left;
    margin: 0 15px 0 65px
}

@media screen and (max-width:800px) {
    .plans-n-pricing__heading {
        font-size: 24px;
        padding-bottom: 6px
    }
    .plans-n-pricing ul {
        margin-left: 25px
    }
}

.plans-n-pricing ul li {
    position: relative;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
    letter-spacing: .3px;
    color: #25292c
}

.plans-n-pricing ul li:after {
    display: block;
    position: absolute;
    top: 5px;
    left: -38px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #74ba2d
}

@media screen and (max-width:800px) {
    .plans-n-pricing ul li {
        margin-top: 21px
    }
    .plans-n-pricing ul li:after {
        left: -29px
    }
}

.emplyer-how-works {
    text-align: center;
    line-height: 1.56;
    padding: 12px 23px 102px
}

@media screen and (max-width:1030px) {
    .emplyer-how-works {
        margin-left: auto;
        margin-right: auto;
        max-width: 800px;
        padding-bottom: 20px
    }
}

.emplyer-how-works__item {
    list-style-type: none;
    display: inline-block;
    position: relative;
    vertical-align: top;
    box-sizing: border-box;
    padding: 0 30px;
    width: 29%;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56
}

@media screen and (min-width:1140px) {
    .emplyer-how-works__item:after {
        position: absolute;
        top: 108px;
        left: -59px;
        display: block;
        content: '';
        width: 111px;
        height: 3px;
        background-size: 111px 3px
    }
}

@media screen and (max-width:1030px) {
    .emplyer-how-works__item {
        width: 100%;
        max-width: 270px;
        margin: -11px auto 30px;
        display: block
    }
}

@media screen and (max-width:530px) {
    .emplyer-how-works__item {
        padding: 0
    }
}

.emplyer-how-works__item:first-child:after {
    display: none
}

.emplyer-how-works__item:before {
    display: block;
    height: 208px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding-bottom: 3px
}

.emplyer-how-works__item.info:before {
    background-size: 200px 200px
}

.emplyer-how-works__item.payroll:before {
    background-size: 200px 200px
}

.emplyer-how-works__item.started:before {
    background-size: 200px 200px
}

.emplyer-how-works__item strong {
    display: block;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 8px
}

.header-fedgov {
    min-height: 740px;
    position: relative;
    width: 100%;
    overflow: hidden;
    background: #e4f9f8
}

.header-fedgov__aligner {
    margin: 0 auto;
    color: #282c41;
    position: relative;
    padding: 146px 30px 315px;
    max-width: 1120px
}

.header-fedgov:after,
.header-fedgov:before {
    display: block;
    position: absolute;
    left: 0;
    content: ' '
}

@media screen and (max-width:800px) {
    .header-fedgov__aligner {
        padding: 94px 24px 418px
    }
}

@media screen and (max-width:530px) {
    .header-fedgov__aligner {
        padding-bottom: 408px
    }
}

.header-fedgov__sub-aligner {
    max-width: 1080px;
    margin: 8px auto 0;
    text-align: center
}

.header-fedgov:after {
    top: 0;
    right: -50px;
    bottom: 28px;
    background-size: 1543px 591px, 1694px 303px;
    pointer-events: none
}

.header-fedgov:before {
    width: 100%;
    height: 115px;
    bottom: 0;
    top: auto;
    background: #f89b6c
}

.header-fedgov h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.17;
    letter-spacing: .4px;
    padding-bottom: 27px
}

@media screen and (max-width:800px) {
    .header-fedgov h1 {
        font-size: 36px;
        padding-bottom: 25px;
        line-height: 1.33
    }
}

@media screen and (max-width:530px) {
    .header-fedgov h1 {
        font-size: 34px;
        padding-bottom: 34px;
        line-height: 1.32
    }
}

.header-fedgov .head-text {
    font-size: 18px;
    line-height: 1.67;
    font-weight: 300;
    max-width: 920px;
    padding: 0 20px;
    margin: 0 auto 41px
}

@media screen and (max-width:800px) {
    .header-fedgov .head-text {
        font-size: 16px;
        line-height: 1.88;
        padding: 0;
        margin-bottom: 30px
    }
    .header-fedgov .head-text br {
        display: none
    }
    .how-does-it-work-fed li:after,
    .how-does-it-work-fed li:before {
        transform: translate(5px, 14px)
    }
}

@media screen and (max-width:530px) {
    .header-fedgov .head-text {
        padding-bottom: 6px
    }
}

.header-fedgov a.apply-button {
    margin: 0 auto
}

.how-does-it-work-fed {
    counter-reset: item;
    list-style: none;
    position: relative;
    padding-top: 42px;
    min-height: 450px;
    margin-bottom: 46px;
    float: right;
    width: 448px
}

@media screen and (max-width:1000px) {
    .how-does-it-work-fed {
        min-height: 0;
        padding: 362px 25px 0;
        margin-bottom: 35px;
        float: none;
        width: auto
    }
}

.how-does-it-work-fed:before {
    display: block;
    position: absolute;
    content: ' ';
    top: 19px;
    right: 445px;
    width: 797px;
    height: 426px;
    background-repeat: no-repeat
}

@media only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:192dpi),
only screen and (min-resolution:2dppx) {
    .how-does-it-work-fed:before {
        background-size: 797px 426px
    }
}

@media screen and (max-width:1000px) {
    .how-does-it-work-fed:before {
        left: 50%;
        height: 314px;
        width: 587px;
        margin-left: -293.5px;
        background-repeat: no-repeat;
        background-size: 587px 314px!important
    }
}

@media screen and (max-width:1000px) and only screen and (-webkit-min-device-pixel-ratio:2),
screen and (max-width:1000px) and only screen and (min-device-pixel-ratio:2),
screen and (max-width:1000px) and only screen and (min-resolution:192dpi),
screen and (max-width:1000px) and only screen and (min-resolution:2dppx) {
    .how-does-it-work-fed:before {
        background-size: 587px 314px
    }
}

@media screen and (max-width:530px) {
    .how-does-it-work-fed {
        padding: 203px 0 0
    }
    .how-does-it-work-fed:before {
        height: 194px;
        width: 363px;
        margin-left: -181.5px;
        top: -18px;
        background-repeat: no-repeat;
        background-size: 363px 194px!important
    }
}

@media screen and (max-width:530px) and only screen and (-webkit-min-device-pixel-ratio:2),
screen and (max-width:530px) and only screen and (min-device-pixel-ratio:2),
screen and (max-width:530px) and only screen and (min-resolution:192dpi),
screen and (max-width:530px) and only screen and (min-resolution:2dppx) {
    .how-does-it-work-fed:before {
        background-size: 363px 194px
    }
}

.how-does-it-work-fed li {
    padding: 15px 10px 45px 98px;
    font-size: 16px;
    line-height: 1.15;
    position: relative
}

@media screen and (max-width:1000px) {
    .how-does-it-work-fed li {
        max-width: 215px;
        padding-right: 0;
        padding-bottom: 27px;
        margin: 0 auto
    }
}

@media screen and (max-width:530px) {
    .how-does-it-work-fed li {
        padding: 15px 0 27px 63px;
        max-width: 300px
    }
}

.how-does-it-work-fed li strong {
    display: block;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 14px;
    line-height: 1
}

.how-does-it-work-fed li:after,
.how-does-it-work-fed li:before {
    display: block;
    position: absolute;
    top: -2px;
    left: -6px;
    width: 70px;
    height: 70px;
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;
    line-height: 62px;
    font-weight: 700;
    color: #fff;
    font-size: 30px;
    background-color: #c82590;
    border: 5px solid #f9f9f9
}

@media screen and (max-width:530px) {
    .how-does-it-work-fed li:after,
    .how-does-it-work-fed li:before {
        transform: translate(-3px, 11px);
        width: 60px;
        height: 60px;
        line-height: 52px
    }
}

.how-does-it-work-fed li:before {
    content: counter(item);
    counter-increment: item
}

.how-does-it-work-fed li:after {
    content: '';
    background: 0 0;
    border: 1px solid #c82590
}

.key-features {
    padding: 72px 0 26px
}

.key-features .base-content__aligner {
    padding: 0
}

.map-image-wide {
    width: 100%;
    margin: -39px 0 93px
}

.soon-updates {
    margin: 2px 330px 69px 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.33
}

.soon-features {
    background-color: #e4f9f8
}

@media screen and (max-width:800px) {
    .key-features {
        padding-top: 74px
    }
    .soon-updates {
        margin: 0 10px 20px;
        text-align: center
    }
    .soon-features {
        padding-top: 70px
    }
    .soon-features .base-content__aligner {
        padding-bottom: 170px;
        padding-left: 10px;
        padding-right: 10px;
        position: relative
    }
}

.soon-features .apply-button {
    float: right;
    margin-top: 4px
}

.soon-features .base-content__aligner {
    max-width: 1020px
}

@media screen and (max-width:530px) {
    .soon-features {
        padding-top: 48px
    }
    .soon-features .base-content__aligner {
        padding-bottom: 144px
    }
}

@media screen and (max-width:800px) {
    .soon-features .apply-button {
        float: none;
        position: absolute;
        bottom: 66px;
        left: 50%;
        transform: translateX(-50%)
    }
}

.mobile-map-analog {
    background-repeat: no-repeat;
    background-position: 50% 0;
    min-height: 435px;
    margin: -50px -30px 0;
    padding: 51px 24px 105px
}

@media only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:192dpi),
only screen and (min-resolution:2dppx) {
    .mobile-map-analog {
        background-size: 764px 480px
    }
}

@media screen and (max-width:535px) {
    .mobile-map-analog {
        background-repeat: no-repeat;
        background-size: 1033px 648px!important;
        background-position: 50% 50%
    }
}

@media screen and (max-width:535px) and only screen and (-webkit-min-device-pixel-ratio:2),
screen and (max-width:535px) and only screen and (min-device-pixel-ratio:2),
screen and (max-width:535px) and only screen and (min-resolution:192dpi),
screen and (max-width:535px) and only screen and (min-resolution:2dppx) {
    .mobile-map-analog {
        background-size: 1033px 648px
    }
}

.mobile-map-analog .ul {
    max-width: 425px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 20px 60px 0 rgba(0, 0, 0, .15);
    padding: 40px 0 40px 68px
}

.mobile-map-analog .ul ul {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    width: 210px;
    margin-left: -4px
}

.mobile-map-analog .ul ul+ul {
    position: relative;
    left: 6px
}

@media screen and (max-width:535px) {
    .mobile-map-analog .ul {
        padding-left: 61px
    }
    .mobile-map-analog .ul ul {
        display: block;
        margin: 0 auto
    }
    .mobile-map-analog .ul ul+ul {
        left: 0
    }
}

.mobile-map-analog .ul li {
    font-size: 18px;
    line-height: 30px;
    color: #393939
}

.mobile-map-analog .ul li span {
    display: inline-block;
    width: 45px;
    color: #cacaca
}

@media screen and (max-width:800px) {
    .emplyee-features_fed {
        padding-top: 37px;
        padding-bottom: 15px
    }
}


/*# sourceMappingURL=promo-styles.min.css.map */

.site_header-nav .left-item,
.swiper-container-no-flexbox .swiper-slide {
    float: left
}

.swiper-slide,
body,
html {
    height: 100%
}

html {
    overflow-y: auto
}

body {
    line-height: 1
}


.about-us__item,
.footer,
.footer__centered,
.footer__info-list,
.header-home__sub-aligner,
.link-btns__item,
.site_header-nav {
    box-sizing: border-box
}


* {
    -webkit-tap-highlight-color: transparent
}

body {
    -webkit-text-size-adjust: 100%;
    background: #fff;
    color: #393939;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    font-family: Rubik, sans-serif;
    min-width: 320px
}

a,
a:visited {
    color: #393939;
    text-decoration: underline
}

a:hover {
    text-decoration: none
}

.site_header-nav {
    margin: 0 auto;
    max-width: 1140px;
    padding: 0 3px;
    text-transform: uppercase;
    text-align: center
}

.site_header-nav-wrap {
    background: #fff;
    height: 80px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 2
}

.site_header-nav .right-item {
    float: right
}

.site_header-nav .logo-item {
    margin: 21px 32px 0 0
}

@media screen and (max-width:1050px) {
    .site_header-nav .logo-item {
        display: none
    }
}

.site_header-nav .logo-item img {
    width: 186px
}

.site_header-nav .btn-item {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    height: 38px;
    line-height: 40px;
    padding: 0 36px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    text-decoration: none;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: #25292c;
    transition: all .15s ease
}

@media screen and (max-width:1050px) {
    .site_header-nav .btn-item {
        display: block;
        float: none;
        height: 56px;
        line-height: 58px;
        margin: 17px 24px 0;
        text-align: center;
        border-radius: 28px
    }
    .site_header-nav .btn-item.transparent {
        border-bottom: 1px solid #eef0f1;
        border-top: 1px solid #eef0f1;
        margin: 0;
        height: 50px;
        line-height: 52px;
        text-align: left
    }
    .site_header-nav .btn-item.transparent:active {
        background: rgba(0, 0, 0, .05)!important
    }
    .site_header-nav .btn-item.transparent:hover {
        text-decoration: none!important
    }
    .site_header-nav .btn-item.transparent+.transparent {
        margin-top: -1px
    }
}

.site_header-nav .btn-item:hover {
    background-color: #c82590;
    color: #fff
}

.site_header-nav .btn-item:active {
    background-color: #193e9c;
    color: #fff
}

.site_header-nav .btn-item.transparent {
    background: 0 0!important;
    margin: 0 25px;
    padding: 0 20px;
    border: none
}

.site_header-nav .btn-item.transparent.login {
    margin: 20px 13px 0 0;
    padding: 0 36px
}

.site_header-nav .btn-item.transparent:hover {
    text-decoration: underline;
    color: #25292c
}

.site_header-nav .btn-item.nav-links {
    margin-top: 0;
    padding: 19px;
    border-bottom: 4px solid #fff;
    transition: border-color .15s ease;
    border-radius: 0;
    font-size: 14px;
    line-height: 42px
}

.site_header-nav .btn-item.nav-links:hover {
    color: #c82590;
    text-decoration: none
}

@media screen and (max-width:1050px) {
    .site_header-nav .btn-item.nav-links {
        border-bottom: none!important;
        height: 35px;
        line-height: 35px;
        padding: 10px 25px
    }
}

.site_header-nav .btn-item.active {
    border-color: #c82590;
    color: #c82590!important
}

.site_header-nav .btn-item.active:hover {
    text-decoration: none
}

.site_header-nav .btn-item_blue {
    background-color: #f79461;
    color: #fff
}

.site_header-nav .btn-item_blue:hover {
    background-color: #fff;
    color: #25292c
}

.site_header-nav .btn-item_blue:active {
    background-color: #fff;
    border-color: #d0d0d0;
    color: #25292c
}

.header-employee {
    position: relative;
    background: #E4F9F8
}

.header-employee__aligner {
    height: 740px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1136px;
    position: relative;
    padding: 163px 30px 0;
    text-align: center;
    color: #000
}

.header-employee.employer-style:after,
.header-employee:before {
    display: block;
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    left: 0
}

.header-employee:before {
    bottom: 0;
    background-size: 2780px, 660px
}

@media screen and (max-width:1140px) {
    .header-employee:before {
        background-position: calc(100% + 820px) 100%
    }
}

.header-employee h1 {
    font-size: 50px;
    line-height: .8;
    color: #282c41;
    margin-bottom: 36px;
    font-weight: 700
}

@media screen and (max-width:850px) {
    .header-employee__aligner {
        padding: 105px 23px 0;
        height: 832px
    }
    .header-employee h1 {
        font-size: 34px;
        font-weight: 700;
        line-height: 1.32;
        margin-bottom: 19px
    }
}

.header-employee .head-text {
    max-width: 860px;
    padding: 0 20px;
    margin: 0 auto 40px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    color: #2a2c3f
}

@media screen and (max-width:850px) {
    .header-employee .head-text {
        font-size: 16px;
        line-height: 1.88;
        max-width: 700px;
        padding: 0;
        margin-bottom: 27px
    }
}

.header-employee .apply-button {
    margin: 0 auto
}

@media screen and (max-width:530px) {
    .header-employee__aligner {
        height: 931px
    }
    .header-employee:before {
        background-position: calc(100% + 947px) 100%
    }
    .header-employee .apply-button {
        width: 240px;
        min-width: 0
    }
}

.header-employee.employer-style {
    border-bottom-color: #d4cfdb;
    background-color: #f9f5e4
}

.header-employee.employer-style .header-employee__aligner {
    padding-top: 139px;
    max-width: 1050px
}

@media screen and (max-width:800px) {
    .header-employee.employer-style .header-employee__aligner {
        padding-top: 104px;
        padding-left: 32px;
        padding-right: 32px;
        height: 882px
    }
}

.header-employee.employer-style h1 {
    font-size: 40px;
    line-height: 1.38;
    margin-bottom: 40px
}

.header-employee.employer-style:after {
    bottom: 63px;
    background-size: 1439px 461px;
    pointer-events: none
}

@media screen and (max-width:800px) {
    .header-employee.employer-style h1 {
        font-size: 34px;
        line-height: 1.32
    }
    .header-employee.employer-style:after {
        background-position: calc(50% + 313px) 100%;
        bottom: 47px
    }
}

@media screen and (max-width:530px) {
    .header-employee.employer-style .header-employee__aligner {
        padding-left: 20px;
        padding-right: 20px;
        height: 1001px
    }
    .header-employee.employer-style:after {
        background-position: calc(50% + 233px) 100%;
        bottom: 40px
    }
}

.header-employee.employer-style:before {
    width: 100%;
    height: 115px;
    left: 0;
    bottom: 0;
    top: auto;
    background: #E7E1C7
}

.employee-ftrs {
    padding-bottom: 20px;
    margin-top: -4px;
    color: #282c41
}

@media screen and (max-width:800px) {
    .employee-ftrs {
        padding-bottom: 6px
    }
}

.employee-ftrs__title {
    float: left;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.46;
    width: 195px
}

.employee-ftrs-features {
    margin-left: 237px
}

@media screen and (max-width:950px) {
    .employee-ftrs__title {
        float: none;
        text-align: center;
        font-size: 30px;
        line-height: 1.17;
        width: auto;
        margin-bottom: 26px
    }
    .employee-ftrs-features {
        text-align: center;
        max-width: 500px;
        margin: 0 auto
    }
}

.employee-ftrs-features__item {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    width: 155px;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
    padding: 12px 5px 5px 67px;
    list-style-type: none
}

.employee-ftrs-features__item+.header-footer-features__item {
    margin-left: -4px
}

@media screen and (max-width:950px) {
    .employee-ftrs-features__item {
        margin-left: 0!important;
        width: 155px!important;
        left: 0!important;
        padding-right: 5px!important;
        padding-bottom: 12px
    }
}

.employee-ftrs-features__item:before {
    display: block;
    content: '';
    position: absolute;
    top: 6px;
    left: 2px;
    width: 60px;
    height: 60px;
    background-size: 31px 24px
}

.employee-ftrs-features__item.debt {
    width: 145px
}

.employee-ftrs-features__item.debt:before {
    background-size: 24px 30px
}

.employee-ftrs-features__item.home {
    width: 164px;
    left: 10px
}

.employee-ftrs-features__item.home:before {
    background-size: 38px 26px
}

.employee-ftrs-features__item.money {
    padding-right: 0;
    width: 130px
}

.employee-ftrs-features__item.money:before {
    background-size: 30px 30px
}

.apply-button,
a.apply-button {
    display: block;
    text-decoration: none;
    color: #fff;
    text-align: center;
    width: 240px;
    height: 70px;
    line-height: 72px;
    border-radius: 35px;
    background-color: #c82590;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase
}

.apply-button:hover,
a.apply-button:hover {
    background-color: #f79461
}

.apply-button:active,
a.apply-button:active {
    background-color: #193e9c;
    outline: 0
}

@media screen and (max-width:530px) {
    .employee-ftrs-features {
        max-width: 300px
    }
    .apply-button,
    a.apply-button {
        width: auto;
        min-width: 260px
    }
}

.mobileMenuToggler {
    position: fixed;
    z-index: 2;
    top: 26px;
    right: 25px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: none
}

.mobileMenuToggler:active span,
.mobileMenuToggler:active span:after,
.mobileMenuToggler:active span:before {
    background-color: #2aa2ce
}

.mobileMenuToggler span {
    display: block;
    position: absolute;
    z-index: 2;
    border-radius: 2px;
    background: #50B6DB;
    width: 32px;
    height: 4px;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -16px;
    transition: all .15s ease-in-out
}

.mobileMenuToggler span:after,
.mobileMenuToggler span:before {
    content: ' ';
    display: block;
    position: absolute;
    transition: all .15s ease-in-out;
    left: 0;
    top: -8px;
    width: 100%;
    height: 100%;
    background-color: #50B6DB;
    border-radius: 2px
}

.mobileMenuToggler span:after {
    top: 8px
}

.mobileMenuToggler:after {
    display: block;
    content: ' ';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px
}

.mobileOpenNav .mobileMenuToggler span {
    background-color: transparent
}

.mobileOpenNav .mobileMenuToggler span:after,
.mobileOpenNav .mobileMenuToggler span:before {
    transform: rotate(45deg);
    top: 0;
    background-color: #a8a8a8
}

.mobileOpenNav .mobileMenuToggler span:after {
    transform: rotate(-45deg)
}

@media screen and (max-width:1050px) {
    .mobileMenuToggler {
        display: block
    }
    .site_header-nav {
        display: none!important
    }
    .mobileOpenNav .site_header-nav {
        position: absolute;
        z-index: 2;
        padding: 85px 0 28px;
        display: flex!important;
        flex-direction: column;
        top: 0;
        right: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0 30px 60px 0 rgba(56, 56, 56, .3)
    }
    .mobileOpenNav .site_header-nav .btn-item.transparent {
        margin-left: 0;
        margin-right: 0!important
    }
    .mobileOpenNav .site_header-nav .btn-item.transparent.login {
        margin-top: -1px;
        order: 101;
        text-align: center
    }
    .mobileOpenNav .site_header-nav .order-last {
        order: 100
    }
}

.item-d-h {
    display: none
}

.logo-item-m {
    position: fixed;
    top: 25px;
    left: 24px
}

.logo-item-m img {
    width: 144px;
    height: 30px
}

@media screen and (max-width:1050px) {
    .item-d-h {
        display: block;
        z-index: 2
    }
    .item-m-h {
        display: none
    }
}

.header-home {
    position: relative;
    width: 100%;
    overflow: hidden
}

.header-home h1 {
    font-size: 44px;
    font-weight: 700;
    line-height: 1.17;
    letter-spacing: .7px;
    margin-bottom: 18px
}

@media screen and (max-width:800px) {
    .header-home h1 {
        font-size: 34px;
        line-height: 1.32;
        margin-bottom: 21px
    }
}

.header-home .head-text {
    font-size: 18px;
    line-height: 1.67;
    font-weight: 300;
    max-width: 765px;
    padding: 0 20px;
    margin: 0 auto 41px
}

@media screen and (max-width:800px) {
    .header-home .head-text {
        font-size: 16px;
        line-height: 1.88;
        margin-bottom: 30px;
        padding: 0 13px 6px
    }
    .header-home .head-text br {
        display: none
    }
}

.header-home:after,
.header-home:before {
    position: absolute;
    z-index: -1;
    height: 50%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #9ED164;
    content: ''
}

.header-home:after {
    top: 0;
    bottom: auto;
    background: #7AEDFF
}

.header-home__titles {
    padding: 0 20px
}

.header-home__aligner {
    position: relative
}

.header-home__sub-aligner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 819px;
    padding: 140px 0 30px;
    text-align: center
}

.header_fixed,
.header_fixed .logo-item-m,
.header_fixed .mobileMenuToggler {
    position: fixed
}

@media screen and (max-width:530px) {
    .header-home__sub-aligner {
        height: 1249px;
        padding: 103px 0 30px;
        background-size: auto 59%
    }
}

@media screen and (max-width:800px) {
    .header-home__sub-aligner {
        height: 1247px;
        padding: 104px 0 32px;
        background-size: auto 59%;
        font-size: 34px;
        line-height: 1.32
    }
}

.header-home_about {
    height: 80px;
    overflow: inherit
}

.header-home_about .site_header-nav .btn-item.transparent {
    margin: 0 2.2px
}

.header-home_about .site_header-nav .btn-item.transparent.login {
    margin: 20px 13px 0 0
}

.header_fixed {
    z-index: 10
}

.header_fixed+div {
    padding-top: 80px
}

.base-content {
    width: 100%;
    overflow: hidden;
    padding-top: 69px
}

.base-content__eployer-why {
    padding-top: 88px;
    padding-bottom: 50px
}

@media screen and (max-width:800px) {
    .base-content__eployer-why {
        padding-bottom: 13px
    }
}

.base-content__eployer-why .base-content__heading {
    margin-bottom: 51px
}

.base-content__eployer-why .emplyee-features__item {
    line-height: 1.56
}

.base-content__eployer-why .emplyee-features__item strong {
    line-height: 1.2;
    padding-bottom: 9px
}

.base-content__how-it-works {
    background-color: #f9f9f9;
    border-top: solid 1px #e9e9e9;
    border-bottom: solid 1px #e9e9e9;
    padding-top: 84px
}

.base-content__how-it-works.fedgov_layout {
    padding-top: 92px;
    border: none
}

.base-content__how-it-works.fedgov_layout .base-content__heading {
    padding-bottom: 20px
}

.base-content__how-it-works.fedgov_layout .base-content__paragraph {
    max-width: 900px
}

@media screen and (max-width:800px) {
    .base-content__how-it-works.fedgov_layout {
        padding-top: 55px
    }
    .base-content__how-it-works.fedgov_layout .base-content__paragraph {
        margin-bottom: 25px
    }
}

@media screen and (max-width:530px) {
    .base-content__how-it-works.fedgov_layout .base-content__paragraph {
        margin-bottom: 60px
    }
}

.base-content__how-it-works .base-content__heading {
    padding-bottom: 73px
}

@media screen and (max-width:800px) {
    .base-content__how-it-works .base-content__heading {
        padding-bottom: 32px
    }
}

.base-content__apply {
    position: relative;
    padding-bottom: 22px;
    margin-top: -106px;
    padding-top: 181px
}

@media screen and (max-width:1150px) {
    .base-content__apply {
        margin-top: 0
    }
}

.base-content__apply .base-content__heading {
    line-height: 1.5;
    max-width: 800px
}

.base-content__apply-link {
    text-align: center;
    padding: 15px 0 136px
}

@media screen and (max-width:800px) {
    .base-content__apply .base-content__heading {
        line-height: 1.25;
        margin-top: 5px;
        margin-bottom: 20px
    }
    .base-content__apply-link {
        padding: 4px 0 55px
    }
}

@media screen and (max-width:530px) {
    .base-content__apply-link {
        padding: 0 0 45px
    }
}

.base-content__apply-link a.apply-button {
    display: inline-block
}

.base-content__apply-link-see {
    text-align: center;
    padding: 15px 0 0
}

.base-content__apply-link-see a.apply-button {
    display: inline-block;
    background: #fff;
    color: #c82590;
    transition: all .15s ease
}

.base-content__apply:after,
.emplyee-features__item.befored:before {
    background-repeat: no-repeat;
    content: ''
}

.base-content__apply-link-see a.apply-button:hover {
    background-color: #f79461;
    color: #fff
}

.base-content__apply-link-see a.apply-button:active {
    background-color: #193e9c;
    outline: 0;
    color: #fff
}

.base-content__apply:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 524px;
    background-position: 52% 0
}

@media only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:192dpi),
only screen and (min-resolution:2dppx) {
    .base-content__apply:after {
        background-size: 1368px 524px
    }
}

@media screen and (max-width:800px) {
    .base-content__apply:after {
        display: none
    }
    .base-content {
        padding-top: 54px
    }
}

.base-content__pt51 {
    padding-top: 51px
}

@media screen and (max-width:800px) {
    .base-content__pt51 {
        padding-top: 62px
    }
}

.base-content__aligner {
    margin: 0 auto;
    max-width: 1136px;
    padding: 0 30px;
    color: #282c41;
    z-index: 1;
    position: relative
}

.base-content__aligner_1460 {
    max-width: 1460px
}

.base-content__heading {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .15px;
    padding-bottom: 14px;
    max-width: 880px;
    margin: 0 auto 14px
}

.base-content__heading_bigger {
    font-size: 45px
}

@media screen and (max-width:800px) {
    .base-content__heading {
        font-size: 30px;
        line-height: 1.17
    }
    .base-content__heading_bigger {
        font-size: 34px;
        line-height: 1.32
    }
}

.base-content__sub-heading {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.67;
    text-align: center
}

@media screen and (max-width:800px) {
    .base-content__sub-heading {
        font-size: 24px;
        line-height: 1.46;
        padding-bottom: 18px
    }
}

.base-content__paragraph {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    color: #2a2c3f;
    max-width: 850px;
    margin: 0 auto 50px;
    padding-top: 4px;
    text-align: center
}

.base-content__paragraph.mb27 {
    margin-bottom: 27px
}

.base-content__paragraph_map {
    margin-bottom: 69px;
    max-width: 650px
}

@media screen and (max-width:800px) {
    .base-content__paragraph {
        font-size: 16px;
        line-height: 1.5;
        padding-top: 0;
        padding-left: 26px;
        padding-right: 26px;
        margin: -2px auto 39px
    }
}

.footer {
    position: relative;
    padding: 73px 0 63px;
    font-size: 14px;
    font-weight: 400;
    color: #9fa5b0
}

.footer a {
    color: #000;
    border-bottom: 1px solid transparent;
    transition: border-color .15s ease-in-out
}

.footer a:hover {
    border-color: #000
}

.footer a.blue {
    color: #f79461
}

.footer a.blue:hover {
    border-color: #f79461
}

.footer a.phone {
    cursor: default;
    border-bottom: none
}

.footer__centered {
    width: 100%;
    max-width: 1156px;
    padding: 0 15px;
    margin: 0 auto
}

.footer__head {
    display: flex;
    padding-bottom: 24px;
    width: 100%;
    max-width: 936px;
    margin: 0 auto
}

.footer__logo {
    position: relative;
    display: block;
    width: 146px;
    margin-top: -15px;
    margin-bottom: 21px;
    border-bottom: none!important
}

.footer__logo img {
    display: block;
    width: 100%
}

.footer__socials a {
    display: inline-block;
    vertical-align: top;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: opacity .15s ease-in-out;
    border-bottom: none
}

.footer__socials a:last-child {
    margin-right: 0
}

.footer__socials a:hover {
    opacity: .85
}

.footer__socials a img {
    width: 100%;
    height: 100%
}

.footer__aside {
    display: flex;
    flex-direction: column;
    width: 146px;
    margin-right: 8.4%
}

.footer__aside-nav {
    margin-bottom: 20px
}

.footer__aside-nav div {
    margin-bottom: 16px
}

.footer__aside-nav div:last-child {
    margin-bottom: 0
}

.footer__aside-nav a {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500
}

.footer__aside-copyrights {
    margin-bottom: 23px;
    font-weight: 300;
    letter-spacing: .2px;
    line-height: 1.2
}

.footer__menu {
    display: flex
}

.footer__menu-col {
    margin-right: 116px
}

.footer__menu-col:last-child {
    margin-right: 0
}

.footer__menu-title {
    display: block;
    margin-bottom: 21px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #959ba8
}

.footer__menu-list li {
    margin-bottom: 16px;
    list-style-type: none
}

.footer__menu-list a {
    color: #000;
    text-decoration: none
}

.footer__help {
    padding-top: 17px
}

.footer__help-button {
    position: relative;
    height: 40px;
    width: 100px;
    font-size: 14px;
    border: none;
    color: #fff;
    margin-top: -12px;
    border-radius: 20px;
    background-color: #f79461;
    text-transform: uppercase
}

.footer__help-button:hover {
    background-color: #79c9e6
}

.footer__help-button:active {
    background-color: #27a5d2;
    outline: 0
}

.footer__help-button:focus {
    outline: 0
}

@media screen and (max-width:800px) {
    .footer__help-button {
        margin-top: 4px
    }
}

.footer__help-button-img {
    position: absolute;
    top: 6px;
    left: 14px;
    width: 28px;
    height: 28px
}

.footer__help-button-text {
    position: absolute;
    top: 12px;
    right: 21px
}

.footer__info {
    padding-top: 37px;
    border-top: solid 1px rgba(214, 217, 218, .5)
}

.footer__info-list {
    width: 100%;
    max-width: 934px;
    margin: 0 auto
}

.footer__info-list li {
    position: relative;
    margin-bottom: 2px;
    padding: 0 0 0 17px;
    color: #9fa5b0;
    font-weight: 400;
    list-style-type: none;
    line-height: 1.3;
    font-size: 10pt
}

.footer__info-list li:before {
    opacity: .5;
    position: absolute;
    left: 6px;
    top: 3px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #959ba8;
    content: ''
}

.footer__info-list li:last-child {
    margin-bottom: 0
}

@media screen and (max-width:800px) {
    .footer {
        padding: 49px 0 39px
    }
    .footer__head {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding-bottom: 40px
    }
    .footer__logo {
        margin-top: 0;
        margin-bottom: 32px
    }
    .footer__aside {
        width: auto;
        margin-right: 0;
        margin-bottom: 40px;
        align-items: center
    }
    .footer__aside-nav {
        margin-bottom: 41px
    }
    .footer__aside-copyrights {
        margin-bottom: 20px
    }
    .footer__menu {
        flex-direction: column;
        align-items: center;
        text-align: center
    }
    .footer__menu-col {
        margin-right: 0;
        margin-bottom: 25px
    }
    .footer__menu-col:last-child {
        margin-bottom: 0
    }
    .footer__help {
        padding-top: 1px
    }
    .footer__info {
        padding-top: 36px;
        margin: 0 -15px
    }
    .footer__info-list {
        padding: 0 32px
    }
    .footer__info-list li {
        margin-bottom: 8px;
        line-height: 1.25
    }
}

@media screen and (max-width:530px) {
    .footer {
        padding: 44px 0 34px
    }
    .footer__info {
        padding-top: 34px
    }
    .footer__info-list {
        padding: 0 22px
    }
    .footer__info-list li {
        margin-bottom: 11px
    }
}

.zEWidget-launcher {
    display: none!important
}

.emplyee-features {
    text-align: center;
    margin: -21px -35px 0
}

@media screen and (max-width:800px) {
    .emplyee-features {
        margin-left: auto;
        margin-right: auto;
        max-width: 800px;
        padding-bottom: 43px
    }
}

.emplyee-features__item {
    list-style-type: none;
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 225px;
    margin: 0 32px 40px
}

.emplyee-features_fed .emplyee-features__item {
    margin-top: -17px;
    margin-bottom: 21px
}

@media screen and (max-width:800px) {
    .emplyee-features__item {
        width: 173px;
        margin-bottom: 3px
    }
}

@media screen and (max-width:530px) {
    .emplyee-features {
        padding-bottom: 0
    }
    .emplyee-features__item {
        margin-bottom: 37px
    }
}

.emplyee-features__item strong {
    display: block;
    color: #000;
    font-size: 18px;
    padding-bottom: 15px
}

.emplyee-features__item strong.employee-features__big-text {
    font-weight: 700;
    font-size: 100px;
    color: #c82590;
    padding: 49px 0 20px
}

.emplyee-features__item.befored:before {
    display: block;
    left: 0;
    width: 100%;
    height: 134px;
    margin-bottom: 9px;
    background-position: 50% 50%
}

.emplyee-features__item.reduce:before {
    background-size: 150px 150px
}

.emplyee-features__item.augment:before {
    background-size: 150px 150px
}

.emplyee-features__item.create:before {
    background-size: 150px 150px
}

.emplyee-features__item.provide:before {
    background-size: 150px 150px
}

.emplyee-features__asteriks {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.79;
    color: #818181;
    padding-bottom: 83px
}

@media screen and (max-width:800px) {
    .emplyee-features__item strong.employee-features__big-text {
        padding-top: 23px
    }
    .emplyee-features__asteriks {
        max-width: 280px;
        margin: 0 auto;
        padding-bottom: 59px
    }
}

.emplyee-features_small {
    margin-bottom: 30px
}

.emplyee-features_small .emplyee-features__item {
    margin: 0 30px 40px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    text-align: center;
    color: #282c41
}

.emplyee-features_small .emplyee-features__item strong {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    color: #282c41;
    padding-bottom: 10px
}

.emplyee-features_small .emplyee-features__item strong.employee-features__big-text {
    font-weight: 700;
    font-size: 60px;
    color: #f79461;
    padding: 30px 0 10px
}

@media screen and (max-width:800px) {
    .emplyee-features_small .emplyee-features__item strong.employee-features__big-text {
        padding-top: 23px
    }
}

@media screen and (max-width:530px) {
    .emplyee-features_small {
        padding-top: 18px;
        margin-bottom: 38px
    }
}

@media screen and (max-width:800px) {
    .emplyee-features_small {
        padding-top: 21px;
        margin-bottom: -3px
    }
    .emplyee-features_small .emplyee-features__item {
        width: 220px;
        margin: 0 0 36px
    }
}

.about-us {
    padding: 82px 0 80px!important;
    background-color: #e4f9f8
}

.about-us .base-content__heading {
    margin-bottom: 46px;
    font-size: 35px
}

.about-us__slider {
    padding-bottom: 15px
}

.about-us__slider .swiper-wrapper {
    margin-bottom: 35px
}

.about-us__slider-controls {
    display: flex;
    justify-content: center;
    align-items: center
}

.about-us__slider-next,
.about-us__slider-prev {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgba(191, 191, 192, .2);
    cursor: pointer
}

.about-us__slider-next:after,
.about-us__slider-next:before,
.about-us__slider-prev:after,
.about-us__slider-prev:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    background: #fff;
    transform: rotate(45deg) translate(-6px, -1px);
    transition: background .15s ease-in-out;
    content: ''
}

.about-us__slider-next:before,
.about-us__slider-prev:before {
    background: #f79461
}

.about-us__slider-next:hover:before,
.about-us__slider-prev:hover:before {
    background: #505c82
}

.about-us__slider-prev:after {
    margin-left: 4px
}

.about-us__slider-next:after,
.about-us__slider-next:before {
    transform: rotate(45deg) translate(-8px, 1px)
}

.about-us__slider-next:after {
    margin-left: -4px
}

.about-us__slider-pagination {
    margin: 0 9px
}

.about-us__slider-pagination span {
    opacity: 1;
    width: 10px;
    height: 10px;
    margin: 0 10px;
    background-color: #f89b6c;
    transition: background .15s ease-in-out
}

.about-us__slider-pagination span:hover {
    background-color: #97dbdc
}

.about-us__slider-pagination span.swiper-pagination-bullet-active {
    background-color: #c82590
}

.about-us__item {
    min-height: 425px;
    padding: 21px 20px 21px 40px;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.565;
    color: #282c41
}

.about-us__item-logo {
    display: flex;
    min-height: 54px;
    align-items: center;
    width: 193px;
    margin: 0 auto 18px
}

.about-us__item-logo img {
    display: block
}

.about-us__item-title {
    display: block;
    width: 193px;
    margin: 0 auto 15px;
    font-weight: 700
}

.about-us__item-text {
    width: 193px;
    margin: 0 auto;
    font-weight: 300
}

@media screen and (max-width:530px) {
    .about-us {
        padding: 66px 0 64px!important
    }
    .about-us .base-content__heading {
        margin-bottom: 46px
    }
}

@media screen and (max-width:800px) {
    .about-us {
        padding: 74px 0 64px!important
    }
    .about-us .base-content__heading {
        margin-bottom: 38px;
        font-size: 29px
    }
    .about-us__slider .swiper-wrapper {
        margin-bottom: 33px
    }
    .about-us__slider-next,
    .about-us__slider-prev {
        display: none
    }
    .about-us__item {
        min-height: 425px;
        padding: 21px 20px 21px 40px;
        background-color: #fff;
        font-size: 16px;
        line-height: 1.565;
        color: #282c41;
        box-sizing: border-box
    }
    .about-us__item-logo {
        margin-bottom: 19px
    }
}

.link-btns {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 20px
}

.link-btns__item {
    display: inline-block;
    width: 350px;
    min-height: 110px;
    margin: 0 21px 20px;
    border-radius: 55px;
    padding: 21px 10px 17px;
    background-color: #c82590;
    box-shadow: 0 5px 30px 0 rgba(156, 188, 34, .35);
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    text-align: center;
    color: #fff!important;
    text-decoration: none!important;
    transition: background .15s ease
}

.link-btns__item strong {
    display: block;
    margin-top: -3px;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5
}

.link-btns__item:hover {
    background-color: #f79461
}

.link-btns__item:active {
    background-color: #193e9c;
    outline: 0
}
@media screen and (max-width:800px){.link-btns{/*flex-direction:column;*/align-items:center}.link-btns__item{display:inline-block;width:280px;min-height:90px;margin:0 0 24px;padding:12px 10px}.link-btns__item strong{margin-top:3px;font-size:20px;font-weight:500;line-height:1}}
.link-btns__item:active {
        background-color: #f79461;
    }
    .header-home__sub-aligner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 819px;*/ max-height: 690px; 
    padding: 140px 0 30px;
     background: url('/assets/img/home2.jpg') center 80px no-repeat; 
    text-align: center;
}
.header-home__titles {
    color: white;
    align-self: flex-end;
    text-align: left;
    width: 50%;
}.header-home .head-text {
    padding-left: 0;
}
.header-home:after, .header-home:before {
    position: absolute;
    z-index: -1;
    height: 50%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #f9f9f9;
    content: '';
}
.header-home:after {
    top: 0;
    bottom: auto;
    background: #f9f9f9;
}
.link-btns {
    align-self: flex-end;
    width: 51%;
    justify-content: flex-start;
}
@media screen and (max-width: 600px){
.link-btns {
    width: 100%;
    justify-content: center;
}.header-home__titles {
    align-self: center;
    text-align: left;
}
}
@media screen and (max-width: 1150px){
    .how-does-it-work-wrap .img {
   display:none;
}
}
@media screen and (max-width: 463px){
.link-btns {
    flex-direction: row;
    /* margin-bottom: 100px */
 }.header-home__titles {
  
    width: 100%;
}
}
.link-btns__item {
   max-height: 110px;
       width: 250px;
min-height: 0;
    box-shadow: 0 2px 10px 0 #00000080;
}
.link-btns__item strong {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
}.footer__head {
    justify-content: space-between;
}
.employee-tabs__item.active .employee-tabs__item_num:before {
    border: transparent; 
    /* top: -19px; */
    /* left: -19px;*/
}
/*# sourceMappingURL=home-styles.min.css.map */


button, html [type="button"], [type="reset"], [type="submit"] {
	-webkit-appearance: button
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText
}

[type="checkbox"], [type="radio"] {
	box-sizing: border-box;
	padding: 0
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
	height: auto
}

[type="search"] {
	-webkit-appearance: textfield;
	outline-offset: -2px
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit
}


.clear {
	clear: both
}
/*form resize*/
form textarea{
	resize: none;
}

.ui-caps {
	text-transform: uppercase
}

.ui-hide{
	display: none !important;
}

.ui-show{
	display: block !important;
}

.ui-img-fluid{
	max-width: 100%;
	max-height: 100%;
	width:100%;
}
.ui-pull-right{
	float: right;
}

.ui-pull-left{
	float: left;
}

.ui-clear {
	clear: both
}

.ui-center {
	margin: 0 auto
}
.ui-padd-box-center{

	display: block;
	width: 85%;
	margin: 0 auto;
	padding: 2rem;
}

.ui-middle-cta a{
	margin: 0 2rem;
}
@media screen and (max-width: 760px) {
	.ui-padd-box-center{
		width: 100%;
	}
}
.ui-float-left {
	float: left
}

.ui-float-right {
	float: right
}

.ui-show-mobile-only {
	display: none !important;
}

.ui-back-to-top{

	background: #fff;
	padding: 0.5rem;
	position: fixed;
	bottom: 10rem;
	right: 4rem;
	border-radius: 3px;

	box-shadow: 0 12px 65px 2px rgba(16, 89, 147, 0.2), 0 1px 2px 1px rgba(24, 122, 223, 0.1);
	transition: all 2s ease;
	transform: translateY(6rem);

	opacity: 0;
}

.ui-back-to-top a{

	font-size: 40px;
	line-height: 30px;
}
.ui-back-to-top.show{
	opacity: 1;
	transform: translateY(0);
}


@media screen and (max-width: 600px) {
	.ui-show-mobile-only {
		display: block !important;
	}
}

@media screen and (max-width: 600px) {
	.ui-hide-mobile-only {
		display: none !important
	}
}
a {
	color: inherit;
	text-decoration: none
}
.ui-group-button a,.ui-group-button .btn{
	margin: 0 0.5rem !important;
}
.ui-group-button a:first-child,.ui-group-button .btn:first-child{
	margin-left: 0;
}
.ui-group-button a:last-child,.ui-group-button .btn:last-child{
	margin-right: 0;
}

.ui-button-shadow{
	box-shadow: 0 6px 40px 2px rgba(26, 106, 186, 0.3);
}
.ui-button-shadow-dark, .ui-box-shadow-dark{
	box-shadow: 0 6px 40px 2px rgba(96, 108, 115, 0.3);
}
.ui-box-shadow-dark-light{
	box-shadow: 0 8px 40px 0 rgba(96, 108, 115, 0.1);
}
.ui-no-box-shadow{
	box-shadow: none  !important;
	-webkit-box-shadow: none !important;
}
.ui-form-file{
	display: none;
}
.ui-file-button{
	text-align: center;
}


.ui-form-step .ui-timeline {
	width: 100%;
	margin: 0;
	padding: 0;
}
.ui-form-step .ui-timeline .ui-timeline-bar{
	padding: 3px 10px;
	background-color: #b4c0c8;
	width: 50px;
	margin: 0 10px;
	border-radius: 100px;
}
.ui-form-step .ui-timeline .ui-timeline-bar:first-child{
	margin-left: 0;
}
.ui-form-step .ui-timeline .ui-timeline-active{
	background-color: #f79461;
}
/* .ui-form-step .ui-timeline-body .ui-timeline-content{
	display: none;
} */
.ui-form-step .ui-timeline-body .ui-timeline-content-active{
	display: block;
}

.ui-form-step .ui-timeline-content {
	border-radius: 3px;
}
/* .ui-form-step .ui-form-step-tab{
	display: none;
} */
.ui-form-step input.ui-step-error,.ui-form-step select.ui-step-error,
.ui-form-step textarea.ui-step-error{
	/*background-color: #ff9eac;*/
	border: 1px solid #fa1f3e;
}
.ui.ui-timeline-bar.ui-timeline-bar-finish{
	background-color: #4CAF50;
}

.ui-dragzone{
	width: 100%;
	padding: 2rem;
	border: 2px dashed rgba(154,167,178,0.2);
	margin-bottom: 2em;

	/*border-image-source: url("../images/assets/bdr-img-size.png");*/
	/*border-image-slice: 2;*/
	/*border-image-repeat: revert;*/
}

.ui-dragzone .ui-dragzone-body{

	background-color: #e0e9ee;
	padding: 3rem 6rem;
	width: 100%;

}
.ui-tilt{
	transform-origin: 0 0;
	transform: skewY(2deg);
}
.ui-no-tilt{

	transform: skewX(0deg) skewY(0deg) !important;
}
@media screen and (max-width: 760px){

	.ui-dragzone .ui-dragzone-body{
		padding: 3rem 3rem;
	}

}
.ui-drag-zone .ui-drag-svg{
	position: relative;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.svg {
	display: inline-block;
	height: 48px;
	width: 48px
}
.svg-small {
	height: 12px;
	width: 12px
}

.svg-social {
	height: 27px
}

.svg-large {
	height: 72px;
	width: 72px
}
.ui-flexbox{
	display:flex !important;
}
.ui-full-bg{

	width: 100%;
	min-height: 100%;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: 50% 50% !important; transition: all 0.5s ease;
}

.ui-full-bg-norm{

	width: 100%;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: 50% 50% !important; transition: all 0.5s ease;
}

.ui-full-bg.bg-fixed{
	background-attachment: fixed !important;
}
/*font size in pixels*/
.fs-norm {
	font-size: 16px !important;
}
.fs-1{
	font-size: 1px !important;
}
.fs-2{
	font-size: 2px !important;
}
.fs-3{
	font-size: 3px !important;
}
.fs-4{
	font-size: 4px !important;
}
.fs-5{
	font-size: 5px !important;
}
.fs-6{
	font-size: 6px !important;
}
.fs-7{
	font-size: 7px !important;
}
.fs-8{
	font-size: 8px !important;
}
.fs-9{
	font-size: 9px !important;
}
.fs-10{
	font-size: 10px !important;
}
.fs-11{
	font-size: 11px !important;
}
.fs-12{
	font-size: 12px !important;
}
.fs-13{
	font-size: 13px !important;
}
.fs-14{
	font-size: 14px !important;
}
.fs-15{
	font-size: 15px !important;
}
.fs-16{
	font-size: 16px !important;
}
.fs-17{
	font-size: 17px !important;
}
.fs-18{
	font-size: 18px !important;
}
.fs-19{
	font-size: 19px !important;
}
.fs-20{
	font-size: 20px !important;
}
.fs-21{
	font-size: 21px !important;
}
.fs-22{
	font-size: 22px !important;
}
.fs-23{
	font-size: 23px !important;
}
.fs-24{
	font-size: 24px !important;
}
.fs-25{
	font-size: 25px !important;
}
.fs-26{
	font-size: 26px !important;
}
.fs-27{
	font-size: 27px !important;
}
.fs-28{
	font-size: 28px !important;
}
.fs-29{
	font-size: 29px !important;
}
.fs-30{
	font-size: 30px !important;
}
.fs-31{
	font-size: 31px !important;
}
.fs-32{
	font-size: 32px !important;
}
.fs-33{
	font-size: 33px !important;
}
.fs-34{
	font-size: 34px !important;
}
.fs-35{
	font-size: 35px !important;
}
.fs-36{
	font-size: 36px !important;
}
.fs-37{
	font-size: 37px !important;
}
.fs-38{
	font-size: 38px !important;
}
.fs-39{
	font-size: 39px !important;
}
.fs-40{
	font-size: 40px !important;
}
.fs-41{
	font-size: 41px !important;
}
.fs-42{
	font-size: 42px !important;
}
.fs-43{
	font-size: 43px !important;
}
.fs-44{
	font-size: 44px !important;
}
.fs-45{
	font-size: 45px !important;
}
.fs-46{
	font-size: 46px !important;
}
.fs-47{
	font-size: 47px !important;
}
.fs-48{
	font-size: 48px !important;
}
.fs-49{
	font-size: 49px !important;
}
.fs-50{
	font-size: 50px !important;
}
/*margins bottom*/
.mrgb{
	margin-bottom: 0.5em !important;
}
.mrgb1{
	margin-bottom: 1em !important;
}
.mrgb2{
	margin-bottom: 2em !important;
}
.mrgb3{
	margin-bottom: 3em !important;
}
.mrgb4{
	margin-bottom: 4em !important;
}
.mrgb5{
	margin-bottom: 5em !important;
}
.mrgb6{
	margin-bottom: 6em !important;
}
.mrgb7{
	margin-bottom: 7em !important;
}
.mrgb8{
	margin-bottom: 8em !important;
}
.mrgb9{
	margin-bottom: 9em !important;
}
.mrgb10{
	margin-bottom: 10em !important;
}
/*Margin top*/
.mrgt{
	margin-top: 0.5em !important;
}
.mrgt1{
	margin-top: 1em !important;
}
.mrgt2{
	margin-top: 2em !important;
}
.mrgt3{
	margin-top: 3em !important;
}
.mrgt4{
	margin-top: 4em !important;
}
.mrgt5{
	margin-top: 5em !important;
}
.mrgt6{
	margin-top: 6em !important;
}
.mrgt7{
	margin-top: 7em !important;
}
.mrgt8{
	margin-top: 8em !important;
}
.mrgt9{
	margin-top: 9em !important;
}
.mrgt10{
	margin-top: 10em !important;
}
/*colors*/
.onteal, .onteal a
{
	color: #00baba !important;
}
.onregentgray, .onregentgray a{
	color: #8796A3 !important;
}
.onprocelain, .onprocelain a{
	color: #F5F7F8 !important;
}
.ontintedblue,.ontintedblue a
{
	color: #e0e5e9 !important;
}
.onblue, .onblue a {
	color: #3395D7 !important;
}
.onhippieblue, .onhippieblue a
{
	color: #579ab3 !important;
}
.onheather, .onheather a
{
	color: #b1c2cd !important;
}
.onjamb, .onjamb a {
	color: #3eb249 !important;
}
.ontwitterblue, .ontwitterblue a {
	color: #4099ff !important;
}
.onfacebookblue, .onfacebookblue a {
	color: #3b5998 !important;
}
.onflamingo, .onflamingo a{
	color: #f26522 !important;
}
.onflamingobright,.onflamingobright a{
	color: #f89a6d !important;
}
.onpoloblue, .onpoloblue a {
	color: #88b0d8 !important;
}
.onblack, .onblack a {
	color: #000000 !important;
}
.ontundora, .ontundora a {
	color: #444444 !important;
}
.oncyan, .oncyan a {
	color: #00aeef !important;
}
.onmineshaft,.onmineshaft a
{
	color: #2c2c2c !important;
}
.onaliz, .onaliz a {
	color: #e74c3c !important;
}
.onatlantis,.onatlantis{
	color: #58D941 !important;
}
.onpolar,.onpolar a{
	color: #f6fafd !important;
}
.oncatskill,.oncatskill a {
	color: #e7ebf4 !important;
}
.onlinkwater,.onlinkwater a{
	color: #ecf0f9 !important;
}
.onathgrey, .onathgrey a {
	color: #e5e9ec !important;
}
.onacapulco, .onacapulco a {
	color: #7cb0a1 !important;
}
.onalspat, .onalspat a {
	color: #34495e !important;
}
.onsilver, .onsilver a {
	color: #bdc3c7 !important;
}
.onsilverlight,.onsilverlight a
{
	color: rgba(185,195,199,0.9) !important;
}
.onconcrete, .onconcrete a {
	color: #95a5a6 !important;
}
.onwhite, .onwhite a {
	 color: #ffffff !important;
 }
.oncorduroy, .oncorduroy a {
	color: #5d6565 !important;
}
.ongoldenbell, .ongoldenbell a
{
	color:#de9312 !important;
}
.onwhitelight, .onwhitelight a {
	color: rgba(255,255,255,0.8) !important;
}
.onshutgray, .onshutgray a {
	color: #596d7a !important;
}
.onpigeonpost, .onpigeonpost a {
	color: #aac0d4 !important;
}
.onapple, .onapple a {
	color: #3eb249 !important;
}
.onappledark, .onappledark a {
	color: #32923b;
}
.onmystic, .onmystic a {
	color: #e2ebef !important;
}
.onmysticlight, .onmysticlight a {
	color: #ecf3f6 !important;
}
.oncerulean, .oncerulean a {
	color: #0ba2db !important;
}
.onronchi,.onronchi a
{
	color: #f1d347 !important;
}
.onwildsand,.onwildsand a
{
	color: #e9edfe !important;
}
.onsushi, .onsushi a
{
	color: #8dc63f !important;
}
.onblacklight,.onblacklight a
{
	color: rgba(0,0,0,0.5) !important;
}
/*background-colors*/
.bg-teal, .bg-teal a{ background-color: #00baba !important; }
.bg-procelain, .bg-procelain a{
	background-color: #F5F7F8 !important;
}
.bg-regentgray, .bg-regentgray a{
	background-color: #8796A3 !important;
}
.bg-mineshaft{
	background-color: #2c2c2c !important;
}
.bg-blacklight
{
	background-color: rgba(0,0,0,0.2) !important;
}
.bg-ronchi
{
	background-color: #f1d347 !important;
}
.bg-sushi
{
	background-color: #8dc63f !important;
}
.bg-wildsand
{
	background-color:#f5f7fe !important;
}
.bg-goldenbell
{
	background-color: #de9312 !important;
}
.bg-tintedblue
{
	background-color: #e0e5e9 !important;
}
.bg-bl90 {
	background-color: #252525 !important;
}
.bg-black {
	background-color: #000 !important;
}
.bg-mystic {
	background-color: #e2ebef !important;
}
.bg-mysticlight {
	background-color: #ecf3f6 !important;
}
.bg-default {
	background-color: #f6f6f6 !important;
	border-bottom: 1px solid #e4e3e3;
}
.bg-jamb {
	background-color: #3eb249 !important;
}
.bg-facebook {
	background-color: #3b5998 !important;
}
.bg-flamingo{
	background-color: #f26522 !important;
}
.bg-twitter {
	background-color: #4099ff !important;
}
.bg-poloblue {
	background-color: #88b0d8 !important;
}
.bg-tundora {
	background-color: #444444 !important;
}
.bg-aliz {
	background-color: #e74c3c !important;
}
.bg-atlantis{
	background-color: #58D941 !important;
}
.bg-polar{
	background-color: #f6fafd !important;
}
.bg-catskill{
	background-color: #e7ebf4 !important;
}
.bg-linkwater{
	background-color: #ecf0f9 !important;
}
.bg-bluedark {
	background-color: #1e2a36 !important;
}
.bg-athgrey {
	background-color: #e5e9ec !important;
}
.bg-turquoise {
	background-color: #40ddbe !important;
}
.bg-cyan {
	background-color: #00aeef !important;
}
.bg-ebonyclay {
	background-color: #252e36 !important;
}
.bg-alsphat {
	background-color: #283848 !important;
}
.bg-white {
	background-color: #ffffff !important;
}
.bg-whitelight {
	background-color: rgba(255,255,255,0.5) !important;
}
.bg-shutgray {
	background-color: #596d7a !important;
}
.bg-pigeonpost {
	background-color: #aac0d4 !important;
}
.bg-acapulco {
	background-color: #7cb0a1 !important;
}
.bg-silver {
	background-color: #bdc3c7 !important;
}
.bg-silverlight {
	background-color: rgba(185,195,199,0.2) !important;
}
.bg-concrete {
	background-color: #95a5a6 !important;
}
.bg-corduroy {
	background-color: #5d6565 !important;
}
.bg-apple {
	background-color: #3eb249 !important;
}
.bg-appledark {
	background-color: #32923b !important;
}
.bg-cerulean {
	background-color: #0ba2db !important;
}
.bg-blue {
	background-color: #3395D7 !important;
}
.bg-heather {
	background-color: #b1c2cd !important;
}
.bg-hippieblue
{
	background-color: #579ab3 !important;
}

.font-weight-xlight {
	font-weight: 100 !important;
}
.font-weight-light {
	font-weight: 300 !important;
}
.font-weight-medium {
	font-weight: 500 !important;
}
.font-weight-bold{
	font-weight: 700 !important;
}

.ui-line-height{
	line-height: 1.5em !important;
}
.ui-line-height-small{
	line-height: 1em !important;
}
.ui-line-height-mini{
	line-height: 0.5em !important;
}
.ui-line-height-large{
	line-height: 2em !important;
}
.ui-line-height-xlarge{
	line-height:3em !important;
}
.letter-space{
	letter-spacing: 0.5em !important;
}
.letter-space-small{
	letter-spacing: 0.35em !important;
}
.letter-space-mini{
	letter-spacing: 0.2em !important;
}
.letter-space-large{
	letter-spacing: 1em !important;
}
.box-center,.box-center [class*="col-"]
{
	float: none !important;
	margin: 0 auto !important;
}

/*border-colors*/
[class*="border-"]{
	border-width: 1px !important;
	border-style: solid !important;
}
.border-mineshaft{
	border-color: #2c2c2c !important;
}
.border-blacklight
{
	border-color: rgba(0,0,0,0.2) !important;
}
.border-ronchi
{
	border-color: #f1d347 !important;
}
.border-sushi
{
	border-color: #8dc63f !important;
}
.border-wildsand
{
	border-color:#f5f5f5 !important;
}
.border-goldenbell
{
	border-color: #de9312 !important;
}
.border-tintedblue
{
	border-color: #e0e5e9 !important;
}
.border-bl90 {
	border-color: #252525 !important;
}
.border-mystic {
	border-color: #e2ebef !important;
}
.border-mysticlight {
	border-color: #ecf3f6 !important;
}
.border-default {
	border-color: #f6f6f6 !important;
	border-bottom: 1px solid #e4e3e3;
}
.border-jamb {
	border-color: #3eb249 !important;
}
.border-facebook {
	border-color: #3b5998 !important;
}
.border-flamingo{
	border-color: #f26522 !important;
}
.border-twitter {
	border-color: #4099ff !important;
}
.border-poloblue {
	border-color: #88b0d8 !important;
}
.border-tundora {
	border-color: #444444 !important;
}
.border-aliz {
	border-color: #e74c3c !important;
}
.border-atlantis{
	border-color: #58D941 !important;
}
.border-polar{
	border-color: #f6fafd !important;
}
.border-catskill{
	border-color: #e7ebf4 !important;
}
.border-linkwater{
	border-color: #ecf0f9 !important;
}
.border-bluedark {
	border-color: #1e2a36 !important;
}
.border-athgrey {
	border-color: #e5e9ec !important;
}
.border-turquoise {
	border-color: #40ddbe !important;
}
.border-cyan {
	border-color: #00aeef !important;
}
.border-ebonyclay {
	border-color: #252e36 !important;
}
.border-alsphat {
	border-color: #283848 !important;
}
.border-white {
	border-color: #ffffff !important;
}
.border-whitelight {
	border-color: rgba(255,255,255,0.5) !important;
}
.border-shutgray {
	border-color: #596d7a !important;
}
.border-pigeonpost {
	border-color: #aac0d4 !important;
}
.border-acapulco {
	border-color: #7cb0a1 !important;
}
.border-silver {
	border-color: #bdc3c7 !important;
}
.border-silverlight {
	border-color: rgba(185,195,199,0.2) !important;
}
.border-concrete {
	border-color: #95a5a6 !important;
}
.border-corduroy {
	border-color: #5d6565 !important;
}
.border-apple {
	border-color: #3eb249 !important;
}
.border-appledark {
	border-color: #32923b !important;
}
.border-cerulean {
	border-color: #0ba2db !important;
}
.border-blue {
	border-color: #3395D7 !important;
}
.border-heather {
	border-color: #b1c2cd !important;
}
.border-hippieblue
{
	border-color: #579ab3 !important;
}
.ui-italiced{
	font-style: italic !important;
}
/*text-aligns*/
.ui-text-left {
	text-align: left !important;
}
.ui-text-right {
	text-align: right !important;
}
.ui-text-center{
	text-align: center !important;
}
.ui-text-justify
{
	text-align: justify !important;
}
.ui-text-shadow
{
	text-shadow:0 1px 0.3em rgba(0,0,0,0.8) !important;
}
.ui-text-shadow-fade
{
	text-shadow:0 1px 0.3em rgba(0,0,0,0.4) !important;
}
.ui-rounded
{
	border-radius: 100em !important;
}
.ui-rounded-small
{
	border-radius: 0.5em !important;
}
.ui-rounded-mini
{
	border-radius: 0.2em !important;
}
.ui-rounded-medium
{
	border-radius: 1em !important;
}
.ui-rounded-large
{
	border-radius: 2em !important;
}
.ui-rounded-alt
{
	border-radius: 0.5em 0 0.5em 0 !important;
}
.ui-rounded-up
{
	border-radius: 0.5em 0.5em 0 0 !important;
}
.ui-rounded-down
{
	border-radius: 0 0 0.5em 0.5em !important;
}

.ui-capitalize {
	text-transform: capitalize !important;
}

.ui-display-inline{ display: inline; }

/*Containers*/
.ui-wrapper {
	width: 100%;
	padding: 3em 0;
}
.ui-wrapper-large {
	width: 100%;
	padding: 5em 0;
}
.ui-wrapper-xlarge {
	width: 100% ;
	padding: 7em 0;
}
.ui-wrapper-xxlarge {
	width: 100%;
	padding: 9em 0;
}

.ui-wrapper-small {
	width: 100%;
	padding: 2em 0;
}
.ui-wrapper-mini {
	width: 100%;
	padding: 1.3em 0;
}
.ui-wrapper-xmini {
	width: 100%;
	padding: 0.8em 0;
}

@media screen and (max-width: 768px){

	.ui-wrapper-xlarge {
		width: 100% !important;
		padding: 3em 0 !important;
	}
	.ui-wrapper-xxlarge{
		padding: 5em 0 !important;
	}

	.ui-push-box-left,.ui-push-box-right{
		padding: 0 !important;
		margin: 0 !important;
	}



}

.ui-separate {
	display: block;
	width: 100%;
	padding: 32px 0;
}
span.ui-separate {
	display: inline-block;
	width: 100%;
	padding: 32px 0;
}
.ui-separate-small {
	width: 100%;
	padding: 4px 0;
	display: block;
}
span.separate-small {
	display: inline-block;
	width: 100%;
	padding: 4px 0;
}

.ui-line {
	width: 100% !important;
	height: 1px;
	margin: 15px 0;
}
.ui-line-half-center
{
	display: block;
	width: 30%;
	height: 1px;
	margin: 1em auto !important;
}
.ui-line-half-center-mini
{
	display: block;
	width: 20%;
	height: 1px;
	margin: 15px auto !important;
}
.ui-line-half-center-xmini
{
	display: block;
	width: 10%;
	height: 1px;
	margin: 15px auto !important;
}
.ui-line-half{
	height: 1px;
	margin: 0.7em 0 !important;
}
.ui-line-half span{
	display: block;
	width: 50%;
	height: 1px;
	margin: 0 !important;
}
.ui-line-half span.center{
	margin: 0 auto !important;
}

.ui-line-half span.right{
	float: right !important;
}

.ui-thin-line-half span.mini{
	width: 25%;
}

.ui-line-half span.xmini{
	width: 18%;
}
.ui-line-half span.xxmini{
	width: 12%;
}

.ui-line-half span.xsm{
	width: 8%;
}

.ui-line-center {
	width: 60%;
	height: 1px;
	margin: 15px auto;
}
/*text-transforms*/
.ui-upcase, .ui-upcase a {
	text-transform: uppercase !important;
}
.ui-lowcase,.ui-lowcase a {
	text-transform: lowercase;
}

.ui-btn-ghost{
	background-color: transparent !important;
	border: 1px solid #ffffff;
	opacity: 0.8;
	transition: all 0.2s ease-in;
}
.ui-btn-angle{

	border-radius: 50px 0 50px 50px !important;

}
.ui-btn-full
{
	display: block !important;
	width: 100% !important;
	padding: 1em 0;
}
a[class*="bg-"],input[class*="bg-"]
{
	border: none !important;
}

/*heights*/
.height-1{
	height: 1px !important;
}
.height-2{
	height: 2px !important;
}
.height-3{
	height: 3px !important;
}
.height-4{
	height: 4px !important;
}
.height-5{
	height: 5px !important;
}
.height-6{
	height: 6px !important;
}
.height-large{
	height: 10px !important;
}
.height-xlarge{
	height: 15px !important;
}

.ui-full-length
{
	height: 100% !important;
}
.full-breadth
{
	display:block !important;
	width: 100% !important;
}
.full-display{
	display:block !important;
	width: 100% !important;
}
.half-breath{
	display:block !important;
	width: 50% !important;
}
.ui-half-length{
	height: 50% !important;
}
.ui-half-display{
	display:block !important;
	width: 50% !important;
}

/*paddings*/
.ui-padding-ups
{
	padding: 0.9em 0 !important;
}
.ui-padding-xups
{
	padding: 1.5em 0 !important;
}
.ui-padding-sides{
	padding:0 0.9em !important;
}
.ui-padding-xsides{
	padding:0 2em !important;
}
.ui-padding{
	padding: 1em 2em!important;
}
.ui-padding-large{
	padding: 2.3em !important;
}
.ui-padding.xl{
	padding: 2.9em !important;
}
.ui-padding-larger{
	padding: 3em;
}
.ui-padding-xlarger{
	padding: 5em;
}

.ui-padding-mini{
	padding: 1em 1em!important;
}

.ui-push-padded-left{

	padding-left: 4em !important;

}

.ui-push-box-right{
	padding-left: 2rem;
}

.ui-push-box-left{
	padding-right: 2rem;
}


ul.ui-unstyled, ol.ui-unstyled {
	margin-left: 0;
	list-style: none;
}
ul.ui-inline, ol.ui-inline {
	margin-left: 0 !important;
	list-style: none;
	padding-left: 0;
}
ul.ui-inline > li, ol.ui-inline > li {
	display: inline-block;
	padding-right: 5px;
	padding-left: 5px;
}
ul.ui-inline > li > a, ol.ui-inline > li > a {
	display: inline-block;
}
ul.ui-inline > li:first-child, ol.ui-inline > li:first-child {
	padding-left: 0;
}

/********  ANIMATION STYLES ********/
/*Different animation styles in Css*/

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.animated.spin3dX,.animated.spin3dY,.animated.spin-alt
{
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}

.animated.fast
{
	-webkit-animation-duration: 4s !important;
	animation-duration: 4s !important;
}
.animated.veryfast
{
	-webkit-animation-duration: 1s !important;
	animation-duration: 1s !important;
}
.animated.slow
{
	-webkit-animation-duration: 8s !important;
	animation-duration: 8s !important;
}
.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}

.animated.bounceIn,
.animated.bounceOut {
	-webkit-animation-duration: .75s;
	animation-duration: .75s;
}

.animated.flipOutX,
.animated.flipOutY {
	-webkit-animation-duration: .75s;
	animation-duration: .75s;
}

@-webkit-keyframes bounce {
	0%, 20%, 53%, 80%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		transform: translate3d(0,0,0);
	}

	40%, 43% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(0, -30px, 0);
	}

	70% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(0, -15px, 0);
	}

	90% {
		transform: translate3d(0,-4px,0);
	}
}

@keyframes bounce {
	0%, 20%, 53%, 80%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		transform: translate3d(0,0,0);
	}

	40%, 43% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(0, -30px, 0);
	}

	70% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		transform: translate3d(0, -15px, 0);
	}

	90% {
		transform: translate3d(0,-4px,0);
	}
}

.bounce {
	-webkit-animation-name: bounce;
	animation-name: bounce;
	transform-origin: center bottom;
}

@-webkit-keyframes flash {
	0%, 50%, 100% {
		opacity: 1;
	}

	25%, 75% {
		opacity: 0;
	}
}

@keyframes flash {
	0%, 50%, 100% {
		opacity: 1;
	}

	25%, 75% {
		opacity: 0;
	}
}

.flash {
	-webkit-animation-name: flash;
	animation-name: flash;
}

@-webkit-keyframes pulse {
	0% {
		transform: scale3d(1, 1, 1);
	}

	50% {
		transform: scale3d(1.05, 1.05, 1.05);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

@keyframes pulse {
	0% {
		transform: scale3d(1, 1, 1);
	}

	50% {
		transform: scale3d(1.05, 1.05, 1.05);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

.pulse {
	-webkit-animation-name: pulse;
	animation-name: pulse;
}

@-webkit-keyframes rubberBand {
	0% {
		transform: scale3d(1, 1, 1);
	}

	30% {
		transform: scale3d(1.25, 0.75, 1);
	}

	40% {
		transform: scale3d(0.75, 1.25, 1);
	}

	50% {
		transform: scale3d(1.15, 0.85, 1);
	}

	65% {
		transform: scale3d(.95, 1.05, 1);
	}

	75% {
		transform: scale3d(1.05, .95, 1);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

@keyframes rubberBand {
	0% {
		transform: scale3d(1, 1, 1);
	}

	30% {
		transform: scale3d(1.25, 0.75, 1);
	}

	40% {
		transform: scale3d(0.75, 1.25, 1);
	}

	50% {
		transform: scale3d(1.15, 0.85, 1);
	}

	65% {
		transform: scale3d(.95, 1.05, 1);
	}

	75% {
		transform: scale3d(1.05, .95, 1);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

.rubberBand {
	-webkit-animation-name: rubberBand;
	animation-name: rubberBand;
}

@-webkit-keyframes shake {
	0%, 100% {
		transform: translate3d(0, 0, 0);
	}

	10%, 30%, 50%, 70%, 90% {
		transform: translate3d(-10px, 0, 0);
	}

	20%, 40%, 60%, 80% {
		transform: translate3d(10px, 0, 0);
	}
}

@keyframes shake {
	0%, 100% {
		transform: translate3d(0, 0, 0);
	}

	10%, 30%, 50%, 70%, 90% {
		transform: translate3d(-10px, 0, 0);
	}

	20%, 40%, 60%, 80% {
		transform: translate3d(10px, 0, 0);
	}
}

.shake {
	-webkit-animation-name: shake;
	animation-name: shake;
}

@-webkit-keyframes swing {
	20% {
		transform: rotate3d(0, 0, 1, 15deg);
	}

	40% {
		transform: rotate3d(0, 0, 1, -10deg);
	}

	60% {
		transform: rotate3d(0, 0, 1, 5deg);
	}

	80% {
		transform: rotate3d(0, 0, 1, -5deg);
	}

	100% {
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

@keyframes swing {
	20% {
		transform: rotate3d(0, 0, 1, 15deg);
	}

	40% {
		transform: rotate3d(0, 0, 1, -10deg);
	}

	60% {
		transform: rotate3d(0, 0, 1, 5deg);
	}

	80% {
		transform: rotate3d(0, 0, 1, -5deg);
	}

	100% {
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

.swing {
	transform-origin: top center;
	-webkit-animation-name: swing;
	animation-name: swing;
}

@-webkit-keyframes tada {
	0% {
		transform: scale3d(1, 1, 1);
	}

	10%, 20% {
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}

	30%, 50%, 70%, 90% {
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}

	40%, 60%, 80% {
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

@keyframes tada {
	0% {
		transform: scale3d(1, 1, 1);
	}

	10%, 20% {
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}

	30%, 50%, 70%, 90% {
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}

	40%, 60%, 80% {
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

.tada {
	-webkit-animation-name: tada;
	animation-name: tada;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes wobble {
	0% {
		transform: none;
	}

	15% {
		transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
	}

	30% {
		transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
	}

	45% {
		transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}

	60% {
		transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}

	75% {
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}

	100% {
		transform: none;
	}
}

@keyframes wobble {
	0% {
		transform: none;
	}

	15% {
		transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
	}

	30% {
		transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
	}

	45% {
		transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}

	60% {
		transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}

	75% {
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}

	100% {
		transform: none;
	}
}

.wobble {
	-webkit-animation-name: wobble;
	animation-name: wobble;
}

@-webkit-keyframes jello {
	11.1% {
		transform: none
	}

	22.2% {
		transform: skewX(-12.5deg) skewY(-12.5deg)
	}
	33.3% {
		transform: skewX(6.25deg) skewY(6.25deg)
	}
	44.4% {
		transform: skewX(-3.125deg) skewY(-3.125deg)
	}
	55.5% {
		transform: skewX(1.5625deg) skewY(1.5625deg)
	}
	66.6% {
		transform: skewX(-0.78125deg) skewY(-0.78125deg)
	}
	77.7% {
		transform: skewX(0.390625deg) skewY(0.390625deg)
	}
	88.8% {
		transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
	}
	100% {
		transform: none
	}
}

@keyframes jello {
	11.1% {
		transform: none
	}

	22.2% {
		transform: skewX(-12.5deg) skewY(-12.5deg)
	}
	33.3% {
		transform: skewX(6.25deg) skewY(6.25deg)
	}
	44.4% {
		transform: skewX(-3.125deg) skewY(-3.125deg)
	}
	55.5% {
		transform: skewX(1.5625deg) skewY(1.5625deg)
	}
	66.6% {
		transform: skewX(-0.78125deg) skewY(-0.78125deg)
	}
	77.7% {
		transform: skewX(0.390625deg) skewY(0.390625deg)
	}
	88.8% {
		transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
	}
	100% {
		transform: none
	}
}



.jello{
	-webkit-animation-name:jello;
	animation-name:jello;

	transform-origin: center
}

@-webkit-keyframes bounceIn {
	0%, 20%, 40%, 60%, 80%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}

	20% {
		transform: scale3d(1.1, 1.1, 1.1);
	}

	40% {
		transform: scale3d(.9, .9, .9);
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
	}

	80% {
		transform: scale3d(.97, .97, .97);
	}

	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}

@keyframes bounceIn {
	0%, 20%, 40%, 60%, 80%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}

	20% {
		transform: scale3d(1.1, 1.1, 1.1);
	}

	40% {
		transform: scale3d(.9, .9, .9);
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
	}

	80% {
		transform: scale3d(.97, .97, .97);
	}

	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}

.bounceIn {
	-webkit-animation-name: bounceIn;
	animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
	0%, 60%, 75%, 90%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: translate3d(0, -3000px, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0);
	}

	75% {
		transform: translate3d(0, -10px, 0);
	}

	90% {
		transform: translate3d(0, 5px, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes bounceInDown {
	0%, 60%, 75%, 90%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: translate3d(0, -3000px, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0);
	}

	75% {
		transform: translate3d(0, -10px, 0);
	}

	90% {
		transform: translate3d(0, 5px, 0);
	}

	100% {
		transform: none;
	}
}

.bounceInDown {
	-webkit-animation-name: bounceInDown;
	animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
	0%, 60%, 75%, 90%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: translate3d(-3000px, 0, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(25px, 0, 0);
	}

	75% {
		transform: translate3d(-10px, 0, 0);
	}

	90% {
		transform: translate3d(5px, 0, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes bounceInLeft {
	0%, 60%, 75%, 90%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: translate3d(-3000px, 0, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(25px, 0, 0);
	}

	75% {
		transform: translate3d(-10px, 0, 0);
	}

	90% {
		transform: translate3d(5px, 0, 0);
	}

	100% {
		transform: none;
	}
}

.bounceInLeft {
	-webkit-animation-name: bounceInLeft;
	animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
	0%, 60%, 75%, 90%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: translate3d(3000px, 0, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(-25px, 0, 0);
	}

	75% {
		transform: translate3d(10px, 0, 0);
	}

	90% {
		transform: translate3d(-5px, 0, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes bounceInRight {
	0%, 60%, 75%, 90%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: translate3d(3000px, 0, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(-25px, 0, 0);
	}

	75% {
		transform: translate3d(10px, 0, 0);
	}

	90% {
		transform: translate3d(-5px, 0, 0);
	}

	100% {
		transform: none;
	}
}

.bounceInRight {
	-webkit-animation-name: bounceInRight;
	animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
	0%, 60%, 75%, 90%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: translate3d(0, 3000px, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}

	75% {
		transform: translate3d(0, 10px, 0);
	}

	90% {
		transform: translate3d(0, -5px, 0);
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes bounceInUp {
	0%, 60%, 75%, 90%, 100% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: translate3d(0, 3000px, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}

	75% {
		transform: translate3d(0, 10px, 0);
	}

	90% {
		transform: translate3d(0, -5px, 0);
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

.bounceInUp {
	-webkit-animation-name: bounceInUp;
	animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
	20% {
		transform: scale3d(.9, .9, .9);
	}

	50%, 55% {
		opacity: 1;
		transform: scale3d(1.1, 1.1, 1.1);
	}

	100% {
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}
}

@keyframes bounceOut {
	20% {
		transform: scale3d(.9, .9, .9);
	}

	50%, 55% {
		opacity: 1;
		transform: scale3d(1.1, 1.1, 1.1);
	}

	100% {
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}
}

.bounceOut {
	-webkit-animation-name: bounceOut;
	animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
	20% {
		transform: translate3d(0, 10px, 0);
	}

	40%, 45% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}

	100% {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}
}

@keyframes bounceOutDown {
	20% {
		transform: translate3d(0, 10px, 0);
	}

	40%, 45% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}

	100% {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}
}

.bounceOutDown {
	-webkit-animation-name: bounceOutDown;
	animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
	20% {
		opacity: 1;
		transform: translate3d(20px, 0, 0);
	}

	100% {
		opacity: 0;
		transform: translate3d(-2000px, 0, 0);
	}
}

@keyframes bounceOutLeft {
	20% {
		opacity: 1;
		transform: translate3d(20px, 0, 0);
	}

	100% {
		opacity: 0;
		transform: translate3d(-2000px, 0, 0);
	}
}

.bounceOutLeft {
	-webkit-animation-name: bounceOutLeft;
	animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
	20% {
		opacity: 1;
		transform: translate3d(-20px, 0, 0);
	}

	100% {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}
}

@keyframes bounceOutRight {
	20% {
		opacity: 1;
		transform: translate3d(-20px, 0, 0);
	}

	100% {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}
}

.bounceOutRight {
	-webkit-animation-name: bounceOutRight;
	animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
	20% {
		transform: translate3d(0, -10px, 0);
	}

	40%, 45% {
		opacity: 1;
		transform: translate3d(0, 20px, 0);
	}

	100% {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}
}

@keyframes bounceOutUp {
	20% {
		transform: translate3d(0, -10px, 0);
	}

	40%, 45% {
		opacity: 1;
		transform: translate3d(0, 20px, 0);
	}

	100% {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}
}

.bounceOutUp {
	-webkit-animation-name: bounceOutUp;
	animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
	0% {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInDownBig {
	0% {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInDownBig {
	-webkit-animation-name: fadeInDownBig;
	animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		transform: translate3d(-2000px, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		transform: translate3d(-2000px, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInLeftBig {
	-webkit-animation-name: fadeInLeftBig;
	animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
	0% {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInRightBig {
	0% {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInRightBig {
	-webkit-animation-name: fadeInRightBig;
	animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
	0% {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInUpBig {
	0% {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInUpBig {
	-webkit-animation-name: fadeInUpBig;
	animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
}

@keyframes fadeOutDown {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
}

.fadeOutDown {
	-webkit-animation-name: fadeOutDown;
	animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}
}

@keyframes fadeOutDownBig {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}
}

.fadeOutDownBig {
	-webkit-animation-name: fadeOutDownBig;
	animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}
}

@keyframes fadeOutLeft {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}
}

.fadeOutLeft {
	-webkit-animation-name: fadeOutLeft;
	animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(-2000px, 0, 0);
	}
}

@keyframes fadeOutLeftBig {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(-2000px, 0, 0);
	}
}

.fadeOutLeftBig {
	-webkit-animation-name: fadeOutLeftBig;
	animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
}

@keyframes fadeOutRight {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
}

.fadeOutRight {
	-webkit-animation-name: fadeOutRight;
	animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}
}

@keyframes fadeOutRightBig {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}
}

.fadeOutRightBig {
	-webkit-animation-name: fadeOutRightBig;
	animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
}

@keyframes fadeOutUp {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
}

.fadeOutUp {
	-webkit-animation-name: fadeOutUp;
	animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}
}

@keyframes fadeOutUpBig {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}
}

.fadeOutUpBig {
	-webkit-animation-name: fadeOutUpBig;
	animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
	0% {
		transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	40% {
		transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	50% {
		transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	80% {
		transform: perspective(400px) scale3d(.95, .95, .95);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	100% {
		transform: perspective(400px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
}

@keyframes flip {
	0% {
		transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	40% {
		transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	50% {
		transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	80% {
		transform: perspective(400px) scale3d(.95, .95, .95);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	100% {
		transform: perspective(400px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
}

.animated.flip {
	-webkit-backface-visibility: visible;
	backface-visibility: visible;
	-webkit-animation-name: flip;
	animation-name: flip;
}

@-webkit-keyframes flipInX {
	0% {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	60% {
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}

	80% {
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}

	100% {
		transform: perspective(400px);
	}
}

@keyframes flipInX {
	0% {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	60% {
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}

	80% {
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}

	100% {
		transform: perspective(400px);
	}
}

.flipInX {
	-webkit-backface-visibility: visible !important;
	backface-visibility: visible !important;
	-webkit-animation-name: flipInX;
	animation-name: flipInX;
}

@-webkit-keyframes flipInY {
	0% {
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	60% {
		transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		opacity: 1;
	}

	80% {
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}

	100% {
		transform: perspective(400px);
	}
}

@keyframes flipInY {
	0% {
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	60% {
		transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		opacity: 1;
	}

	80% {
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}

	100% {
		transform: perspective(400px);
	}
}

.flipInY {
	-webkit-backface-visibility: visible !important;
	backface-visibility: visible !important;
	-webkit-animation-name: flipInY;
	animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
	0% {
		transform: perspective(400px);
	}

	30% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		opacity: 1;
	}

	100% {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		opacity: 0;
	}
}

@keyframes flipOutX {
	0% {
		transform: perspective(400px);
	}

	30% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		opacity: 1;
	}

	100% {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		opacity: 0;
	}
}

.flipOutX {
	-webkit-animation-name: flipOutX;
	animation-name: flipOutX;
	-webkit-backface-visibility: visible !important;
	backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
	0% {
		transform: perspective(400px);
	}

	30% {
		transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
		opacity: 1;
	}

	100% {
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		opacity: 0;
	}
}

@keyframes flipOutY {
	0% {
		transform: perspective(400px);
	}

	30% {
		transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
		opacity: 1;
	}

	100% {
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		opacity: 0;
	}
}

.flipOutY {
	-webkit-backface-visibility: visible !important;
	backface-visibility: visible !important;
	-webkit-animation-name: flipOutY;
	animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
	0% {
		transform: translate3d(100%, 0, 0) skewX(-30deg);
		opacity: 0;
	}

	60% {
		transform: skewX(20deg);
		opacity: 1;
	}

	80% {
		transform: skewX(-5deg);
		opacity: 1;
	}

	100% {
		transform: none;
		opacity: 1;
	}
}

@keyframes lightSpeedIn {
	0% {
		transform: translate3d(100%, 0, 0) skewX(-30deg);
		opacity: 0;
	}

	60% {
		transform: skewX(20deg);
		opacity: 1;
	}

	80% {
		transform: skewX(-5deg);
		opacity: 1;
	}

	100% {
		transform: none;
		opacity: 1;
	}
}

.lightSpeedIn {
	-webkit-animation-name: lightSpeedIn;
	animation-name: lightSpeedIn;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
	0% {
		opacity: 1;
	}

	100% {
		transform: translate3d(100%, 0, 0) skewX(30deg);
		opacity: 0;
	}
}

@keyframes lightSpeedOut {
	0% {
		opacity: 1;
	}

	100% {
		transform: translate3d(100%, 0, 0) skewX(30deg);
		opacity: 0;
	}
}

.lightSpeedOut {
	-webkit-animation-name: lightSpeedOut;
	animation-name: lightSpeedOut;
	-webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
	0% {
		transform-origin: center;
		transform: rotate3d(0, 0, 1, -200deg);
		opacity: 0;
	}

	100% {
		transform-origin: center;
		transform: none;
		opacity: 1;
	}
}

@keyframes rotateIn {
	0% {
		transform-origin: center;
		transform: rotate3d(0, 0, 1, -200deg);
		opacity: 0;
	}

	100% {
		transform-origin: center;
		transform: none;
		opacity: 1;
	}
}

.rotateIn {
	-webkit-animation-name: rotateIn;
	animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
	0% {
		transform-origin: left bottom;
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}

	100% {
		transform-origin: left bottom;
		transform: none;
		opacity: 1;
	}
}

@keyframes rotateInDownLeft {
	0% {
		transform-origin: left bottom;
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}

	100% {
		transform-origin: left bottom;
		transform: none;
		opacity: 1;
	}
}

.rotateInDownLeft {
	-webkit-animation-name: rotateInDownLeft;
	animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
	0% {
		transform-origin: right bottom;
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}

	100% {
		transform-origin: right bottom;
		transform: none;
		opacity: 1;
	}
}

@keyframes rotateInDownRight {
	0% {
		transform-origin: right bottom;
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}

	100% {
		transform-origin: right bottom;
		transform: none;
		opacity: 1;
	}
}

.rotateInDownRight {
	-webkit-animation-name: rotateInDownRight;
	animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
	0% {
		transform-origin: left bottom;
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}

	100% {
		transform-origin: left bottom;
		transform: none;
		opacity: 1;
	}
}

@keyframes rotateInUpLeft {
	0% {
		transform-origin: left bottom;
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}

	100% {
		transform-origin: left bottom;
		transform: none;
		opacity: 1;
	}
}

.rotateInUpLeft {
	-webkit-animation-name: rotateInUpLeft;
	animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
	0% {
		transform-origin: right bottom;
		transform: rotate3d(0, 0, 1, -90deg);
		opacity: 0;
	}

	100% {
		transform-origin: right bottom;
		transform: none;
		opacity: 1;
	}
}

@keyframes rotateInUpRight {
	0% {
		transform-origin: right bottom;
		transform: rotate3d(0, 0, 1, -90deg);
		opacity: 0;
	}

	100% {
		transform-origin: right bottom;
		transform: none;
		opacity: 1;
	}
}

.rotateInUpRight {
	-webkit-animation-name: rotateInUpRight;
	animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
	0% {
		transform-origin: center;
		opacity: 1;
	}

	100% {
		transform-origin: center;
		transform: rotate3d(0, 0, 1, 200deg);
		opacity: 0;
	}
}

@keyframes rotateOut {
	0% {
		transform-origin: center;
		opacity: 1;
	}

	100% {
		transform-origin: center;
		transform: rotate3d(0, 0, 1, 200deg);
		opacity: 0;
	}
}

.rotateOut {
	-webkit-animation-name: rotateOut;
	animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
	0% {
		transform-origin: left bottom;
		opacity: 1;
	}

	100% {
		transform-origin: left bottom;
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}
}

@keyframes rotateOutDownLeft {
	0% {
		transform-origin: left bottom;
		opacity: 1;
	}

	100% {
		transform-origin: left bottom;
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}
}

.rotateOutDownLeft {
	-webkit-animation-name: rotateOutDownLeft;
	animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
	0% {
		transform-origin: right bottom;
		opacity: 1;
	}

	100% {
		transform-origin: right bottom;
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}

@keyframes rotateOutDownRight {
	0% {
		transform-origin: right bottom;
		opacity: 1;
	}

	100% {
		transform-origin: right bottom;
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}

.rotateOutDownRight {
	-webkit-animation-name: rotateOutDownRight;
	animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
	0% {
		transform-origin: left bottom;
		opacity: 1;
	}

	100% {
		transform-origin: left bottom;
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}

@keyframes rotateOutUpLeft {
	0% {
		transform-origin: left bottom;
		opacity: 1;
	}

	100% {
		transform-origin: left bottom;
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}

.rotateOutUpLeft {
	-webkit-animation-name: rotateOutUpLeft;
	animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
	0% {
		transform-origin: right bottom;
		opacity: 1;
	}

	100% {
		transform-origin: right bottom;
		transform: rotate3d(0, 0, 1, 90deg);
		opacity: 0;
	}
}

@keyframes rotateOutUpRight {
	0% {
		transform-origin: right bottom;
		opacity: 1;
	}

	100% {
		transform-origin: right bottom;
		transform: rotate3d(0, 0, 1, 90deg);
		opacity: 0;
	}
}

.rotateOutUpRight {
	-webkit-animation-name: rotateOutUpRight;
	animation-name: rotateOutUpRight;
}

@-webkit-keyframes hinge {
	0% {
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
	}

	20%, 60% {
		transform: rotate3d(0, 0, 1, 80deg);
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
	}

	40%, 80% {
		transform: rotate3d(0, 0, 1, 60deg);
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
		opacity: 1;
	}

	100% {
		transform: translate3d(0, 700px, 0);
		opacity: 0;
	}
}

@keyframes hinge {
	0% {
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
	}

	20%, 60% {
		transform: rotate3d(0, 0, 1, 80deg);
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
	}

	40%, 80% {
		transform: rotate3d(0, 0, 1, 60deg);
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
		opacity: 1;
	}

	100% {
		transform: translate3d(0, 700px, 0);
		opacity: 0;
	}
}

.hinge {
	-webkit-animation-name: hinge;
	animation-name: hinge;
}

@-webkit-keyframes rollIn {
	0% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes rollIn {
	0% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.rollIn {
	-webkit-animation-name: rollIn;
	animation-name: rollIn;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
	}
}

@keyframes rollOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
	}
}

.rollOut {
	-webkit-animation-name: rollOut;
	animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
	0% {
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}

	50% {
		opacity: 1;
	}
}

@keyframes zoomIn {
	0% {
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}

	50% {
		opacity: 1;
	}
}

.zoomIn {
	-webkit-animation-name: zoomIn;
	animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
	0% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

@keyframes zoomInDown {
	0% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

.zoomInDown {
	-webkit-animation-name: zoomInDown;
	animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
	0% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

@keyframes zoomInLeft {
	0% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

.zoomInLeft {
	-webkit-animation-name: zoomInLeft;
	animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
	0% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

@keyframes zoomInRight {
	0% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

.zoomInRight {
	-webkit-animation-name: zoomInRight;
	animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
	0% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

@keyframes zoomInUp {
	0% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

.zoomInUp {
	-webkit-animation-name: zoomInUp;
	animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}

	100% {
		opacity: 0;
	}
}

@keyframes zoomOut {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}

	100% {
		opacity: 0;
	}
}

.zoomOut {
	-webkit-animation-name: zoomOut;
	animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
	40% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	100% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
		transform-origin: center bottom;
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

@keyframes zoomOutDown {
	40% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	100% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
		transform-origin: center bottom;
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

.zoomOutDown {
	-webkit-animation-name: zoomOutDown;
	animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
	40% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
	}

	100% {
		opacity: 0;
		transform: scale(.1) translate3d(-2000px, 0, 0);
		transform-origin: left center;
	}
}

@keyframes zoomOutLeft {
	40% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
	}

	100% {
		opacity: 0;
		transform: scale(.1) translate3d(-2000px, 0, 0);
		transform-origin: left center;
	}
}

.zoomOutLeft {
	-webkit-animation-name: zoomOutLeft;
	animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
	40% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
	}

	100% {
		opacity: 0;
		transform: scale(.1) translate3d(2000px, 0, 0);
		transform-origin: right center;
	}
}

@keyframes zoomOutRight {
	40% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
	}

	100% {
		opacity: 0;
		transform: scale(.1) translate3d(2000px, 0, 0);
		transform-origin: right center;
	}
}

.zoomOutRight {
	-webkit-animation-name: zoomOutRight;
	animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
	40% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	100% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
		transform-origin: center bottom;
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

@keyframes zoomOutUp {
	40% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}

	100% {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
		transform-origin: center bottom;
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}

.zoomOutUp {
	-webkit-animation-name: zoomOutUp;
	animation-name: zoomOutUp;
}

@-webkit-keyframes slideInDown {
	0% {
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInDown {
	0% {
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

.slideInDown {
	-webkit-animation-name: slideInDown;
	animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
	0% {
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInLeft {
	0% {
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

.slideInLeft {
	-webkit-animation-name: slideInLeft;
	animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
	0% {
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInRight {
	0% {
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

.slideInRight {
	-webkit-animation-name: slideInRight;
	animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
	0% {
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInUp {
	0% {
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

.slideInUp {
	-webkit-animation-name: slideInUp;
	animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		visibility: hidden;
		transform: translate3d(0, 100%, 0);
	}
}

@keyframes slideOutDown {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		visibility: hidden;
		transform: translate3d(0, 100%, 0);
	}
}

.slideOutDown {
	-webkit-animation-name: slideOutDown;
	animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		visibility: hidden;
		transform: translate3d(-100%, 0, 0);
	}
}

@keyframes slideOutLeft {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		visibility: hidden;
		transform: translate3d(-100%, 0, 0);
	}
}

.slideOutLeft {
	-webkit-animation-name: slideOutLeft;
	animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		visibility: hidden;
		transform: translate3d(100%, 0, 0);
	}
}

@keyframes slideOutRight {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		visibility: hidden;
		transform: translate3d(100%, 0, 0);
	}
}

.slideOutRight {
	-webkit-animation-name: slideOutRight;
	animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		visibility: hidden;
		transform: translate3d(0, -100%, 0);
	}
}

@keyframes slideOutUp {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		visibility: hidden;
		transform: translate3d(0, -100%, 0);
	}
}

.slideOutUp {
	-webkit-animation-name: slideOutUp;
	animation-name: slideOutUp;
}

/* Cube Animation def */
@-webkit-keyframes spin3dZ
{
	from
	{
		transform:rotateZ(0deg);
	}
	to
	{
		transform:rotateZ(-360deg);
	}
}
@keyframes spin3dZ
{
	from
	{
		transform:rotateZ(0deg);
	}
	to
	{
		transform:rotateZ(-360deg);
	}
}
.spin3dZ
{
	-webkit-animation-name: spin3dZ;
	animation-name: spin3dZ;
}
@-webkit-keyframes spin3dY
{
	from
	{
		transform:rotateY(0deg);
	}
	to
	{
		transform:rotateY(-360deg);
	}
}
@keyframes spin3dY
{
	from
	{
		transform:rotateY(0deg);
	}
	to
	{
		transform:rotateY(-360deg);
	}
}
.spin3dY
{
	-webkit-animation-name: spin3dY;
	animation-name: spin3dY;
}
@-webkit-keyframes spin-alt
{
	from,to  { -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
	16%      { -webkit-transform: rotateY(-90deg);                           }
	33%      { -webkit-transform: rotateY(-90deg) rotateZ(90deg);            }
	50%      { -webkit-transform: rotateY(-180deg) rotateZ(90deg);           }
	66%      { -webkit-transform: rotateY(-270deg) rotateX(90deg);           }
	83%      { -webkit-transform: rotateX(90deg);                            }
}
@keyframes spin-alt
{
	from,to {
		transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
	}
	16% {
		transform: rotateY(-90deg);
	}
	33% {
		transform: rotateY(-90deg) rotateZ(90deg);
	}
	50% {
		transform: rotateY(-180deg) rotateZ(90deg);
	}
	66% {
		transform: rotateY(-270deg) rotateX(90deg);
	}
	83% {
		transform: rotateX(90deg);
	}
}
.spin-alt
{
	-webkit-animation-name: spin-alt;
	animation-name: spin-alt;
}
@-webkit-keyframes spin3dX
{
	from
	{
		transform:rotateX(0deg);
	}
	to
	{
		transform:rotateX(-360deg);
	}
}
@keyframes spin3dX
{
	from
	{
		transform:rotateX(0deg);
	}
	to
	{
		transform:rotateX(-360deg);
	}
}
.spin3dX
{
	-webkit-animation-name: spin3dX;
	animation-name: spin3dX;
}


.ui-table table{

	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;

}

.ui-table th {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #f8f9fa;
	background-color: #eff5f9;
	font-weight: normal;
}

.ui-table thead th {
	vertical-align: middle;
}
.ui-table [class*='btn-sm']{
	padding: 0.2rem 0.45rem;
	text-transform: uppercase;
	border-radius: 2px;
	font-size: 12px;
}
.ui-table tbody tr td{
	padding: 1rem 0.75em;
}
.ui-table tbody tr:first-child td{
	padding-top: 1rem;
}
.ui-table.striped tbody tr:nth-of-type(odd){
	background-color: rgba(0, 0, 0, 0.05);
}
.ui-table thead th:first-child, .ui-table tbody tr td:first-child{
	padding-left: 2rem;
}
.ui-table thead th:last-child, .ui-table tbody tr td:last-child{
	padding-right: 2rem;
}

.ui-link-norm{
	text-decoration: none !important;
}

.ui-avatar{
	width: 80px;
	height: 80px;
}
.ui-avatar-sm{
	width: 50px;
	height: 50px;
}

.ui-avatar-xsm{
	width: 40px;
	height: 40px;
}

.ui-avatar-mini{
	width: 30px;
	height: 30px;
}
.ui-avatar-large{
	width: 120px;
	height: 120px;
}
.ui-avatar-xlarge{
	width: 150px;
	height: 150px;
}
.ui-button-fb .btn{

	z-index: 1000000;
	padding: 0.0rem 0.5rem;

}
.ui-button-fb .btn [class*='lendlot-']{

	font-size: 12px;
	line-height: 10px;
	position: relative;
	vertical-align: middle;
}

.ui-slider-box{
	width: 100%;
}

.ui-slider .ui-slider-item{
	padding: 20px;
	/* offset-x | offset-y | blur-radius | spread-radius | color */
	box-shadow: 0 15px 40px 1px rgba(96, 108, 115, 0.1);
	border-radius: 5px;
}
.ui-slider .ui-slider-item .weekly-box{
	background-color: #edf2f6;
}
.ui-no-padding{
	padding: 0 !important;
}

.ui-no-margin{
	margin: 0 !important;
}
ul, ol{
    list-style: none;
}

.b-overlay { background-color: rgba(0,0,0,0.5); opacity: 0;}
/*.b-overlay { background-color: rgba(23,83,132,0.7); }*/

/*brand colors*/
.brand-alspat, .brand-alspat a{
    color: #1F3662 !important;
}
.brand-blue, .brand-blue a{
    color: #f79461!important;
}
.brand-green, .brand-green a{
    color: #36c78a !important;
}
.brand-orange, .brand-orange a{
    color: #F58124 !important;
}
.brand-yellow{
    color: #FFAE00 !important;
}
.brand-dark{
    color: #5F6F7F !important;
}
.brand-neutral{
    color: #9AA7B2  !important;
}


.bg-brand-alspat{
    background-color: #1F3662 !important;
}

.bg-brand-neutral {
    background-color: #9AA7B2 !important;
}
.bg-brand-blue {
    background-color: #f79461!important;
}
.bg-brand-green{
    background-color: #36c78a !important;
}
.bg-brand-orange{
    background-color: #F58124 !important;
}
.bg-brand-dark{
    background-color: #5F6F7F !important;
}
.bg-brand-yellow{
    background-color: #FFAE00 !important;
}

.m-auto {
    margin: auto !important; }

.mt-auto,
.my-auto {
    margin-top: auto !important; }

.mr-auto,
.mx-auto {
    margin-right: auto !important; }

.mb-auto,
.my-auto {
    margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
    margin-left: auto !important; }





.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ef5350;
  color: #fff;
}

.card-counter.success {
  background-color: #66bb6a;
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 5em;
  opacity: 0.2;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 65px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}

.btnprimary {
  background-color: #c82590;
  border-color: #c82590;
  color: #fff;
}

.btnsecondary {
  background-color: #f79461;
  border-color: #f79461;
  color: #fff;
}

.btnwhite {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}

/*New Style*/
.top-list {
  display: flex;
  list-style: none;
  flex-wrap: nowrap;
  justify-content: center;
}

.list-item {
  /* // border-right: solid 2px #d4d4d4; */
  background-color: #d4d4d4;
  padding: 10px;
  border-right: 0.3px solid #f4f7fa;
  color: #fffcf4;
  font-size: medium;
}

.list-item.active,
.list-item:hover {
  background-color: #f46a29;
  transition: ease-in 0.5s;
}

.list-item.pactive {
  background-color: purple;
  transition: ease-in 0.5s;
}

/* .pannel {
  background-color: #fff;
  width: 80%;
  margin: 30px auto;

  padding: 30px 30px;
  border-radius: 6px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
} */

.head {
  font-family: Mitr, sans-serif;
  color: #f46a29;
}

.btn-secondary {
  color: #fff;
  background-color: #f46a29;
  border-color: #f46a29;
}

#personal-details {
  border-radius: 4px, 0 0 4px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.label {
  padding: 10px;
  background-color: #f46a29;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}
